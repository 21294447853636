import { useState, FC, useEffect, SetStateAction } from 'react';
import { useSendTaskMutation, useUpdateTaskDeadlineMutation } from '../../api/tenders';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { Button, Tooltip, message } from 'antd';
import { useAppSelector } from '../../hooks';
import LetterIcon from '../../assets/LetterIcon.svg';
import arrow from '../../assets/arrow-right-white.svg';
import attention from '../../assets/attention.svg';
import edit from '../../assets/editAdminIcon.svg';
import moment from 'moment';
import { TaskDeadlineModal } from '../Modals/TaskDeadline';
import { SpinnerIcon } from '../Icons/SpinnerIcon';
import { SupplierScorecard } from './EvaluatePhase3Components/SupplierScorecard';
import { SupplierCard } from './EvaluatePhase3Components/SupplierCard';
import './phases.scss';
import { SupplierReportCard } from './EvaluatePhaseComponent/SupplierReportCard';
import { SupplierList } from './EvaluatePhaseComponent/SupplierList';
import { AllTasksCompleted } from './AllTasksCompleted';
import { CampusTour } from './EvaluatePhase3Components/CampusTour';
import { SupplierListNew } from './DesignPhaseComponents/SupplierListNew';

interface PropsPhase {
    data: tenderDataProps;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EvaluatePhase3: FC<PropsPhase> = ({ data, setTrigger, setConfirmModal }) => {
    const { role } = useAppSelector((state) => state.profileReducer);
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [deadlineModal, setDeadlineModal] = useState(false);
    const [SelectedDate, setSelectedDate] = useState<Date | string>(new Date());
    const [isSuccess, setIsSuccess] = useState(false);

    const [updateDeadline, { isLoading }] = useUpdateTaskDeadlineMutation();

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                id: data.id,
                data: { deadline_at: moment(SelectedDate).format('YYYY-MM-DD') }
            };
            await updateDeadline(formData).unwrap();
            await setDeadlineModal(false);
            await setIsSuccess(true);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    useEffect(() => {
        if (!deadlineModal) {
            setIsSuccess(false);
        }
    }, [deadlineModal]);

    const [
        sendTask,
        { data: dataSendTask, isError: isErrorSendTask, isSuccess: isSuccessSendTask, isLoading: isLoadingSendTask }
    ] = useSendTaskMutation();

    const [messageApi, contextHolder] = message.useMessage();

    const sendTaskFunc = async () => {
        let formData = {};

        formData = {
            id: data?.id
        };
        try {
            await sendTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    useEffect(() => {
        if (isErrorSendTask) {
            messageApi.error('Something went wrong, please try again');
        }
        if (isSuccessSendTask) {
            messageApi.success(dataSendTask.message);
        }
    }, [isErrorSendTask, isSuccessSendTask]);

    return (
        <>
            {contextHolder}
            <div className={`tendermain__block tasks`}>
                {role !== 'school_user' &&
                    (data?.phase_tasks?.[0]?.status === 'pending_tr' ||
                        data?.phase_tasks?.[0]?.status === 'pending_su' ||
                        data?.phase_tasks?.[0]?.status === 'need_resend_tr' ||
                        data?.phase_tasks?.[0]?.status === 'need_approve_tr') && (
                        <div className="tasks__header define">
                            <div className="count-wrapper">
                                Task Centre
                                <div className="count">{data?.phase_task_count}</div>
                            </div>
                            {(data?.phase_tasks?.[0]?.status === 'pending_tr' ||
                                data?.phase_tasks?.[0]?.status === 'need_resend_tr' ||
                                data.phase_tasks?.[0]?.action === 'campus_tour_attendee_details') && (
                                <div className="tasks__deadline-wrapper">
                                    <img src={attention} />
                                    {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                    {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                                </div>
                            )}
                        </div>
                    )}
                {role === 'school_user' && (
                    <div className="tasks__header design">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {(data?.phase_tasks?.[0]?.action === 'supplier_scorecard' ||
                            data?.phase_tasks?.[0]?.action === 'supplier_report_card') &&
                            data?.phase_tasks?.[0]?.status === 'pending_su' && (
                                <div className="tasks__deadline-wrapper">
                                    <img src={attention} />
                                    {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                    {moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}
                                </div>
                            )}
                    </div>
                )}

                <div className="tasks__list">
                    {role !== 'school_user' && data.phase_tasks?.[0]?.action !== 'campus_tour_attendee_details' && (
                        <div className="tasks__info-wrap info-wrap" style={{ margin: '0 0 16px 0' }}>
                            <div className="info-wrap__deadline">Task Deadline for School User</div>
                            <div className="info-wrap__status">
                                {data?.tasks_deadline_at
                                    ? moment(data?.tasks_deadline_at).format('YYYY-MM-DD')
                                    : 'Not indicated'}
                            </div>
                            <div className="info-wrap__edit" onClick={() => setDeadlineModal(true)}>
                                <img src={edit} />
                                Edit
                            </div>
                        </div>
                    )}
                    {data.phase_tasks.length ? (
                        <>
                            <SupplierScorecard
                                data={data}
                                role={role}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                            />
                            <SupplierCard
                                data={data}
                                role={role}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                                setDeadlineModal={setDeadlineModal}
                                isSuccess={isSuccess}
                            />
                            <SupplierReportCard
                                data={data}
                                role={role}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                            />
                            <SupplierListNew data={data} role={role} messageApi={messageApi} setTrigger={setTrigger} />
                            <CampusTour
                                data={data}
                                role={role}
                                messageApi={messageApi}
                                setTrigger={setTrigger}
                                setDeadlineModal={setDeadlineModal}
                            />
                        </>
                    ) : (
                        <>
                            {role === 'school_user' && (
                                <div className="noTasks">
                                    <img src={LetterIcon} />
                                    <div className="noTasks__title"> Welcome to Evaluation Gate 3!</div>
                                    <div className="noTasks__subtitle">
                                        Watch this space - new tasks for you will be posted here as they become
                                        available.
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>

                {role === 'tender_representative' &&
                    data?.phase_tasks?.[0]?.status === 'pending_tr' &&
                    data?.phase_tasks?.[0]?.action !== 'campus_tour_attendee_details' &&
                    !data.can_update_phase && (
                        <div className="tasks__btn-wrap">
                            <div style={{ width: '47%' }}></div>

                            <Tooltip
                                title={
                                    !data?.can_send_task && data.phase_tasks[1]?.status !== 'pending_su'
                                        ? !data?.tasks_deadline_at
                                            ? 'To send this task, please set a deadline for the school user first'
                                            : !data?.suppliers?.every((elem) => elem.grade?.value)
                                            ? 'To send this task, you need to assign a score to each supplier first'
                                            : 'To send this task, you need to complete all the Task Activities'
                                        : ''
                                }
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Send Task <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}

                {role === 'school_user' &&
                    data.phase_tasks.length > 0 &&
                    data.phase_tasks?.[0].status === 'pending_su' && (
                        <div className="tasks__btn-wrap">
                            <div></div>

                            <Tooltip
                                title="To send this task, you need to complete all the Task Activities"
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Send Task <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                {role === 'tender_representative' &&
                    (data?.phase_tasks?.[0]?.status === 'need_resend_tr' ||
                        data?.phase_tasks?.[0]?.status === 'need_approve_tr') &&
                    data.phase_tasks?.[0]?.action !== 'supplier_report_card' &&
                    data.phase_tasks?.[0]?.action !== 'campus_tour_attendee_details' && (
                        <div className="tasks__btn-wrap">
                            <div style={{ width: '53%' }}></div>
                            <Tooltip
                                title="To send this task, please set a deadline for the school user first"
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <div
                                    className={`tasks__resend ${
                                        data?.phase_tasks?.[0]?.status === 'need_resend_tr' && data.can_send_task
                                            ? ''
                                            : 'disable'
                                    }`}
                                    onClick={() => {
                                        if (data?.phase_tasks?.[0]?.status === 'need_resend_tr' && data.can_send_task) {
                                            sendTaskFunc();
                                        }
                                    }}
                                >
                                    <SpinnerIcon
                                        fill={
                                            data?.phase_tasks?.[0]?.status === 'need_resend_tr' && data.can_send_task
                                                ? '#001965'
                                                : '#b3bad1 '
                                        }
                                        style={{ margin: '0 10px 0 0' }}
                                    />
                                    Resend All Suppliers{' '}
                                </div>
                            </Tooltip>
                            <Button
                                className={`tasks__next-btn ${
                                    data?.phase_tasks?.[0]?.status === 'need_approve_tr' && data.can_send_task
                                        ? ''
                                        : 'disabled'
                                }`}
                                type="primary"
                                onClick={() => {
                                    if (data?.phase_tasks?.[0]?.status === 'need_approve_tr' && data.can_send_task) {
                                        sendTaskFunc();
                                    }
                                }}
                            >
                                Next Task <img src={arrow} />
                            </Button>
                        </div>
                    )}
                {role === 'tender_representative' &&
                    (data?.phase_tasks?.[0]?.status === 'need_resend_tr' ||
                        data?.phase_tasks?.[0]?.status === 'need_approve_tr') &&
                    data.phase_tasks?.[0]?.action === 'supplier_report_card' && (
                        <div className="tasks__btn-wrap">
                            <div style={{ width: '53%' }}></div>
                            <Tooltip
                                title="To send this task, please set a deadline for the school user first"
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <div
                                    className={`tasks__resend ${
                                        data?.phase_tasks?.[0]?.status === 'need_resend_tr' && data.can_send_task
                                            ? ''
                                            : 'disable'
                                    }`}
                                    onClick={() => {
                                        if (data?.phase_tasks?.[0]?.status === 'need_resend_tr' && data.can_send_task) {
                                            sendTaskFunc();
                                        }
                                    }}
                                >
                                    <SpinnerIcon
                                        fill={
                                            data?.phase_tasks?.[0]?.status === 'need_resend_tr' && data.can_send_task
                                                ? '#001965'
                                                : '#b3bad1 '
                                        }
                                        style={{ margin: '0 10px 0 0' }}
                                    />
                                    Resend All Suppliers{' '}
                                </div>
                            </Tooltip>
                            <Button
                                className={`tasks__next-btn ${data?.can_update_phase ? '' : 'disabled'}`}
                                type="primary"
                                onClick={() => {
                                    if (data.can_update_phase) {
                                        setConfirmModal(true);
                                    }
                                }}
                            >
                                Next Phase <img src={arrow} />
                            </Button>
                        </div>
                    )}
                {role === 'tender_representative' &&
                    data.phase_tasks?.[0]?.action === 'campus_tour_attendee_details' && (
                        <div className="tasks__btn-wrap">
                            <div style={{ width: '53%' }}></div>
                            <Tooltip
                                title="To send this task, please set a deadline for the school user first"
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Notify School <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
            </div>
            {deadlineModal && (
                <TaskDeadlineModal
                    openModal={deadlineModal}
                    setOpenModal={setDeadlineModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};
