export const Message = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        style={{ margin: '0 5px 0 0' }}
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.99687 14.3952H4.39687C3.51287 14.3952 2.79688 13.6792 2.79688 12.7952V4.79519C2.79688 3.91119 3.51287 3.19519 4.39687 3.19519H15.5969C16.4809 3.19519 17.1969 3.91119 17.1969 4.79519V12.7952C17.1969 13.6792 16.4809 14.3952 15.5969 14.3952H10.6969L7.32887 17.3912C6.81287 17.8504 5.99687 17.484 5.99687 16.7936V14.3952Z"
            stroke="#B3BAD1"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M5.99609 6.792H13.9961"
            stroke="#B3BAD1"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M5.99609 10.392H11.5961"
            stroke="#B3BAD1"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
