import { Divider } from 'antd';
import dots from '../../assets/dots.svg';
import School from '../../assets/schoolicon.svg';
import MoneyIcon from '../../assets/MoneyIcon.svg';
import idicon from '../../assets/idicon.svg';
import onTrack from '../../assets/onTrack.svg';
import messageIcon from '../../assets/chat-message.svg';
import Tasklist from '../../assets/Task list, Document, Text.svg';
import clock from '../../assets/clock-time-cross.svg';
import flag from '../../assets/Flag, Circle.svg';
import { PhaseI } from '../../api/types/common';
import CancelBtn from '../../assets/CancelBtn.svg';
import document from '../../assets/document-checkmark.svg';

import { FC, useEffect, useRef, useState } from 'react';
import edit from '../../assets/editAdminIcon.svg';
import { useAppSelector } from '../../hooks';
import { useClickOutside } from '../../hooks/useClickOutSide';
import { useNavigate } from 'react-router-dom';
import supplierIcon from '../../assets/User,Profile.svg';
import arrow from '../../assets/arrow.svg';
import moment from 'moment';
import flagIcon from '../../assets/flag-2.svg';

interface Props {
    item: PhaseI;
    setIsOpenEditTenderModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectTender: React.Dispatch<React.SetStateAction<number | null>>;
    setOpenCancelTenderModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedTender: React.Dispatch<React.SetStateAction<PhaseI | null>>;
    setOpenManage: React.Dispatch<React.SetStateAction<number | null>>;
    openManage: number | null;
    type: string;
}

export const KanbanItemOR: FC<Props> = ({
    item,
    setIsOpenEditTenderModal,
    setSelectTender,
    setOpenCancelTenderModal,
    setSelectedTender,
    setOpenManage,
    openManage,
    type
}) => {
    const { role } = useAppSelector((state) => state.profileReducer);
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [openSuppliers, setOpenASuppliers] = useState(false);

    useEffect(() => {
        if (!openManage) {
            setSelectTender(null);
        }
    }, [openManage]);

    useClickOutside(ref, () => setOpenManage(null));

    return (
        <div
            className={`kanban__card card OR ${type === 'ongoing' ? 'notClicable' : ''}`}
            onClick={() => {
                if (type === 'ongoing') return;
                navigate(`/tender/${item.id}`);
            }}
        >
            <div className="card__row top" ref={ref}>
                <div className="card__title">{item.expense_area_name} tender</div>
            </div>
            <div className="card__main main">
                {type === 'ongoing' && (
                    <div className="main__row">
                        <img src={document} />
                        <div className="main__name">{item.phase_name}</div>
                    </div>
                )}
                <div className="main__row">
                    <img src={School} />
                    <div className="main__name">{item.school_name}</div>
                </div>

                <div className="main__row">
                    <img src={idicon} />
                    <div className="main__name">{item.number}</div>
                </div>
            </div>
            <div className="card__row">
                {item.phase_task_status === 'on_track' && (
                    <div className="card__status on-track">
                        <img src={onTrack} />
                        On-track
                    </div>
                )}
                {item.phase_task_status === 'late' && (
                    <div className="card__status late">
                        <img src={clock} />
                        Late
                    </div>
                )}
                {item.phase_task_status === 'follow_up' && (
                    <div className="card__status follow_up">
                        <img src={flag} />
                        Follow-up
                    </div>
                )}
            </div>

            {(type === 'early_bird' || type === 'live') && (
                <>
                    <Divider style={{ width: '100%', marginLeft: '-0px', margin: '16px 0 16px 0px' }} />
                    <div className="card__row">
                        <div className={`card__task Registrations`}>
                            <img src={Tasklist} />
                            Registrations
                        </div>
                        <div className="card__row">
                            {type === 'live' && <img src={flagIcon} style={{ margin: '0 5px 0 0' }} />}
                            {item.registrations}
                        </div>
                    </div>
                    <div className="card__row">
                        <div className="card__block market">
                            Market size <div className="card__value">{item.market_size}</div>
                        </div>
                        <div
                            className={`card__block ${
                                item.confirmed_registrations === 0
                                    ? 'gray'
                                    : item.confirmed_registrations >= 1 || item.confirmed_registrations <= 5
                                    ? 'red'
                                    : item.confirmed_registrations > 5
                                    ? 'green'
                                    : ''
                            } `}
                        >
                            Confirmed Reg. <div className="card__value">{item.confirmed_registrations}</div>
                        </div>
                    </div>
                </>
            )}
            {type === 'ongoing' && (
                <div>
                    <Divider style={{ width: '100%', marginLeft: '-0px', margin: '16px 0 0 0' }} />
                    <div className="card__suppliers" onClick={() => setOpenASuppliers((prev) => !prev)}>
                        <div className="card__row" style={{ width: '160px' }}>
                            <img src={supplierIcon} />
                            Suppliers
                            <div className="card__number blue">{item?.suppliers?.length}</div>
                        </div>
                        <img src={arrow} className={`card__arrow ${openSuppliers ? 'active' : ''}`} />
                    </div>

                    {openSuppliers && (
                        <div className="card__supplier-list">
                            {item.suppliers.map((elem) => {
                                return (
                                    <div className="card__supplier-item supplier-item">
                                        <div className="supplier-item__title">
                                            ({elem.name_prefix}) {elem.name}
                                        </div>
                                        <div className="supplier-item__subtitle">{elem.trading_name}</div>
                                        <br />
                                        <div className="supplier-item__title">{elem.manager_name}</div>
                                        <div className="supplier-item__subtitle">
                                            {elem.email} {elem.phone_number}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
