import { SupplierI, taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { FC, useRef, useEffect, useState, ChangeEvent } from 'react';
import plus from '../../../assets/Plus, Add.svg';
import copyImage from '../../../assets/copyImage.svg';
import SupplierListIcon from '../../../assets/SupplierListIcon.svg';
import { Button, message, Modal, Switch, Tooltip } from 'antd';
import i from '../../../assets/i.svg';
import stopImage from '../../../assets/stopImage.svg';
import done from '../../../assets/Done, Check.svg';
import gradeIcon from '../../../assets/GradeIcon.svg';
import messageIcon from '../../../assets/chat-message.svg';
import gradeHistory from '../../../assets/gradeHistory.svg';
import { AwardSuppliserModal } from '../../Modals/AwardSuppliserModal';
import ReceivedFile from '../../../assets/ReceivedFile.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import messageGrey from '../../../assets/messageGrey.svg';

import attantion from '../../../assets/point.svg';
import dots from '../../../assets/dots.svg';
import arrow from '../../../assets/arrow.svg';

import edit from '../../../assets/editAdminIcon.svg';
import Visit from '../../../assets/Visit.svg';

import {
    useShortlistSupplierMutation,
    useUpdateGradeMutation,
    useUpdateIncumbentInTenderMutation,
    useUpdateIncumbentMutation
} from '../../../api/tenders';
import { Loader } from '../../Loader/Loader';
import { AddSuppliersModal } from '../../Modals/AddSuppliersModal';
import Confirmed from '../../../assets/Confirmed.svg';
import Unconfirmed from '../../../assets/Unconfirmed.svg';
import deleteIcon from '../../../assets/DeleteIcon.svg';
import Unverified from '../../../assets/Unverified.svg';
import Verified from '../../../assets/Verified.svg';
import editIcon from '../../../assets/editAdminIcon.svg';
import { EditSupplierModal } from '../../Modals/EditSupplierModal';
import { AddConfirmedSuppliersModal } from '../../Modals/AddConfirmedSuppliersModal';
import { useDeleteSupplierMutation } from '../../../api/tenders';
import pendingIcon from '../../../assets/panding-circle.svg';
import { RejectSupplierModal } from '../../Modals/RejectSupplierModal';
import { RejectIncumbentSupplier } from '../../Modals/RejectIncumbentSupplier';
import { useClickOutside } from '../../../hooks/useClickOutSide';
import Delete from '../../Icons/Delete';
import { downloadFunc } from '../features';
import Plus from '../../Icons/Plus';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export const SupplierListNew: FC<DefineSupportingDocumentTaskProps> = ({ data, role, messageApi, setTrigger }) => {
    const [supplierModal, setSupplierModal] = useState(false);
    const [SupplierData, setSupplierData] = useState<any>(null);
    const [taskId, setTaskId] = useState<number | null>(null);
    const [isCreateSupplierModal, setIsCreateSupplierModal] = useState(false);
    const [isRejectIncumbentModal, setIsRejectIncumbentModal] = useState(false);
    const [isRejectModal, setIsRejectModal] = useState(false);
    const [updateGrade, { isSuccess: isSuccessUpdatedGrade }] = useUpdateGradeMutation();
    const [deleteSupplier, { data: deletedData, isSuccess: isSuccessDeleted, isLoading: isLoadingDelete }] =
        useDeleteSupplierMutation();
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [updateIncumbent, { isSuccess: isSuccessUpdateIncumbent }] = useUpdateIncumbentMutation();
    const [updateIncumbentInTender, { isSuccess: isSuccessUpdateIncumbentInTender }] =
        useUpdateIncumbentInTenderMutation();
    const [selectedSupplier, setSelectedSupplier] = useState<SupplierI | null>(null);
    const [shortlist, { data: shortlistData, isSuccess, isLoading: isLoadingShortList }] =
        useShortlistSupplierMutation();
    const [openManage, setOpenManage] = useState<number | null>(null);
    const [grades, setGrades] = useState<{ id: number; grade: any }[]>([]);
    const ref = useRef<HTMLDivElement | null>(null);
    useClickOutside(ref, () => setOpenManage(null));
    const [openState, setOpenState] = useState<{ id: number; open: boolean }[]>([]);
    const [isSuppAwardModal, setIsSuppAwardModal] = useState(false);

    console.log('grades', grades);

    useEffect(() => {
        if (isSuccessUpdatedGrade) {
            setTrigger((prev) => !prev);
        }
    }, [isSuccessUpdatedGrade]);

    useEffect(() => {
        if (data) {
            const grades = data?.suppliers?.map((elem) => ({
                id: elem.id,
                grade: elem?.grade?.value ? elem?.grade?.value : null
            }));
            setGrades(grades);

            const opens = data?.suppliers?.map((elem) => ({
                id: elem.id,
                open: false
            }));
            setOpenState(opens);
        }
    }, [data]);

    useEffect(() => {
        if (isSuccess) {
            setTrigger((prev: boolean) => !prev);
            messageApi?.success(shortlistData?.message);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccessDeleted) {
            setTrigger((prev) => !prev);
            message.success(deletedData?.message);
            setIsDeleteModal(false);
        }
    }, [isSuccessDeleted]);

    const copyAllEmails = () => {
        const emailsArray: any = [];
        data?.invited_suppliers?.items.forEach((elem) => {
            if (elem?.manager?.email) {
                emailsArray.push(elem.manager.email);
            }
        });

        navigator.clipboard.writeText(emailsArray.join(' '));
        message.success('Supplier emails copied to clipboard successfully');
    };
    const copyAllEmailsInTender = () => {
        const emailsArray: any = [];
        data?.suppliers.forEach((elem) => {
            emailsArray.push(elem.email);
        });

        navigator.clipboard.writeText(emailsArray.join(' '));
        message.success('Supplier emails copied to clipboard successfully');
    };

    useEffect(() => {
        if (isSuccessUpdateIncumbent || isSuccessUpdateIncumbentInTender) {
            setTrigger((prev: boolean) => !prev);
        }
    }, [isSuccessUpdateIncumbent, isSuccessUpdateIncumbentInTender]);

    const statusImage = (status: string) => {
        if (status === 'Unverified') {
            return Unverified;
        }
        if (status === 'Verified') {
            return Verified;
        }
    };

    const deleteSupplierFunc = async () => {
        if (isLoadingDelete) return;
        try {
            const dataToPost = {
                tenderId: data.id,
                supplierId: SupplierData?.id
            };
            deleteSupplier(dataToPost);
        } catch {}
    };

    const shortlistFunc = async (id: number) => {
        try {
            const dataToPost = {
                tenderId: data.id,
                supplierId: id
            };
            shortlist(dataToPost);
        } catch {}
    };

    const classNameOfCompletedSuppliersCounter = (number: number) => {
        if (number === 0) {
            return 'grey';
        }
        if (number >= 1 && number <= 5) {
            return 'red';
        }
        if (number >= 6 && number <= 9) {
            return 'yellow';
        }
        if (number >= 10) {
            return 'green';
        }
    };

    const handleKeyPressNumbersOnly = (e: ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value;
        const onlyNumbers = input.replace(/[^0-9]/g, '');
        const limitedValue = parseInt(onlyNumbers, 10) > 100 ? '100' : onlyNumbers;
        e.target.value = limitedValue;
    };

    const classNameOfGrade = (key: string) => {
        if (key === 'A') {
            return 'light-Green';
        }
        if (key === 'B') {
            return 'Dark-Green';
        }
        if (key === 'C') {
            return 'Yellow';
        }
        if (key === 'D') {
            return 'Orange';
        }
        if (key === 'F') {
            return 'Brown';
        }
        if (key === 'E') {
            return 'Red';
        }
    };

    return (
        <>
            {data?.phase_tasks.length > 0 &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'design_suppliers' || elem.action === 'launch_suppliers') {
                        if (role === 'onboarding_representative') {
                            if (data.invited_suppliers.items.length < 1) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>

                                        <img
                                            src={plus}
                                            className="task__plus"
                                            onClick={() => {
                                                setSupplierModal(true);
                                            }}
                                        />
                                    </div>
                                );
                            }
                            if (data.invited_suppliers.items.length >= 1) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                                <div
                                                    className={`task-complete__i-wrap ${classNameOfCompletedSuppliersCounter(
                                                        data.invited_suppliers.completed_registration
                                                    )}`}
                                                >
                                                    {data.invited_suppliers.completed_registration}
                                                    <Tooltip
                                                        title={
                                                            <div className="tooltip-light">
                                                                <div className="tooltip-light__row">
                                                                    <div>Only registered</div>
                                                                    <div className="tooltip-light__number">
                                                                        {data.invited_suppliers.only_registered}
                                                                    </div>
                                                                </div>
                                                                <div className="tooltip-light__row">
                                                                    <div>Completed registration</div>
                                                                    <div className="tooltip-light__number">
                                                                        {data.invited_suppliers.completed_registration}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        className={'tooltip-white'}
                                                    >
                                                        <img src={i} className="task-complete__i" />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <img
                                                src={plus}
                                                className="task__plus"
                                                onClick={() => {
                                                    setSupplierModal(true);
                                                }}
                                            />
                                        </div>

                                        {data.invited_suppliers.items.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header">
                                                        <img src={statusImage(elem.company.status_name)} />{' '}
                                                        {elem.company.status_name}
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">{elem.company.name}</div>
                                                            <div className="supplier__subtitle">
                                                                {elem.company.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager?.name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager?.name ? elem.manager.name : ''}
                                                                    <div
                                                                        className={`supplier__status ${
                                                                            elem.status_name === 'Unconfirmed'
                                                                                ? 'Unconfirmed'
                                                                                : 'Confirmed'
                                                                        }`}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                elem.status_name === 'Unconfirmed'
                                                                                    ? Unconfirmed
                                                                                    : Confirmed
                                                                            }
                                                                        />{' '}
                                                                        {elem.status_name}
                                                                    </div>
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem.manager?.email ? elem.manager?.email : ''}{' '}
                                                                    {elem.manager?.phone_number
                                                                        ? elem.manager?.phone_number
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                        <img
                                                            src={deleteIcon}
                                                            className="supplier__delete"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setSupplierData(elem);
                                                                setIsDeleteModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        }
                        if (role === 'tender_representative' || role === 'super_admin') {
                            if (data.invited_suppliers.items.length < 1) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                    </div>
                                );
                            }
                            if (data.invited_suppliers.items.length >= 1) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                                <div
                                                    className={`task-complete__i-wrap ${classNameOfCompletedSuppliersCounter(
                                                        data.invited_suppliers.completed_registration
                                                    )}`}
                                                >
                                                    {data.invited_suppliers.completed_registration}
                                                    <Tooltip
                                                        title={
                                                            <div className="tooltip-light">
                                                                <div className="tooltip-light__row">
                                                                    <div>Only registered</div>
                                                                    <div className="tooltip-light__number">
                                                                        {data.invited_suppliers.only_registered}
                                                                    </div>
                                                                </div>
                                                                <div className="tooltip-light__row">
                                                                    <div>Completed registration</div>
                                                                    <div className="tooltip-light__number">
                                                                        {data.invited_suppliers.completed_registration}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        className={'tooltip-white'}
                                                    >
                                                        <img src={i} className="task-complete__i" />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className="task__copyemails" onClick={copyAllEmails}>
                                                <img src={copyImage} />
                                                Copy all Emails
                                            </div>
                                        </div>

                                        {data.invited_suppliers.items.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company.status_name)} />{' '}
                                                            {elem.company.status_name}
                                                        </div>
                                                        <div className="supplier__wrapper switcher">
                                                            Incumbent
                                                            <Switch
                                                                checked={elem.incumbent}
                                                                size="small"
                                                                className="custom-switch"
                                                                onChange={() => {
                                                                    const dataToPost = {
                                                                        tender_id: data.id,
                                                                        invite_id: elem.id
                                                                    };
                                                                    updateIncumbent(dataToPost);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">{elem.company.name}</div>
                                                            <div className="supplier__subtitle">
                                                                {elem.company.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager?.name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager?.name ? elem.manager.name : ''}
                                                                    <div
                                                                        className={`supplier__status ${
                                                                            elem.status_name === 'Unconfirmed'
                                                                                ? 'Unconfirmed'
                                                                                : 'Confirmed'
                                                                        }`}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                elem.status_name === 'Unconfirmed'
                                                                                    ? Unconfirmed
                                                                                    : Confirmed
                                                                            }
                                                                        />{' '}
                                                                        {elem.status_name}
                                                                    </div>
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem.manager?.email ? elem.manager?.email : ''}{' '}
                                                                    {elem.manager?.phone_number
                                                                        ? elem.manager?.phone_number
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                        {elem.manager?.name && (
                                                            <img
                                                                src={editIcon}
                                                                className="supplier__delete"
                                                                onClick={() => {
                                                                    setTaskId(elem.id);
                                                                    setSupplierData(elem);
                                                                    setIsEditModal(true);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        }
                    }
                    if (elem.action === 'evaluate_1_2_suppliers') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem.approved && !data.suppliers.length) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>

                                        <img
                                            src={plus}
                                            className="task__plus"
                                            onClick={() => {
                                                setIsCreateSupplierModal(true);
                                            }}
                                        />
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_tr' && !elem.approved && data.suppliers.length) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green

                              `}
                                        ref={ref}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__row-btns">
                                                <div className="task__copyemails" onClick={copyAllEmailsInTender}>
                                                    <img src={copyImage} />
                                                    Copy all Emails
                                                </div>
                                                <img
                                                    src={plus}
                                                    className="task__plus"
                                                    onClick={() => {
                                                        setIsCreateSupplierModal(true);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {data.suppliers.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company_status_name)} />{' '}
                                                            {elem.company_status_name}
                                                        </div>
                                                        <div className="supplier__wrapper switcher">
                                                            Incumbent
                                                            <Switch
                                                                checked={elem.incumbent}
                                                                size="small"
                                                                className="custom-switch"
                                                                onChange={() => {
                                                                    const dataToPost = {
                                                                        tender_id: data.id,
                                                                        supplier_id: elem.id
                                                                    };
                                                                    updateIncumbentInTender(dataToPost);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">
                                                                ({elem.name_prefix}) {elem.name}
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {elem.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager_name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager_name ? elem.manager_name : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem?.email ? elem?.email : ''}{' '}
                                                                    {elem?.phone_number ? elem?.phone_number : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}

                                                        <div className="supplier__grade grade">
                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {elem.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}
                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <div className="grade__icon_wrapper">
                                                                    <img src={gradeIcon} />
                                                                </div>
                                                            </Tooltip>
                                                            <div className="grade__value_wrapper">
                                                                Grade:{' '}
                                                                <input
                                                                    className="grade__input"
                                                                    maxLength={3}
                                                                    onInput={handleKeyPressNumbersOnly}
                                                                    value={
                                                                        grades.find((grade) => elem.id === grade.id)
                                                                            ?.grade
                                                                    }
                                                                    onChange={(e) => {
                                                                        const { value } = e.target;
                                                                        const index = grades.findIndex(
                                                                            (grade) => elem.id === grade.id
                                                                        );

                                                                        if (index !== -1) {
                                                                            const updatedGrades = grades.map(
                                                                                (grade, i) =>
                                                                                    i === index
                                                                                        ? { ...grade, grade: value }
                                                                                        : grade
                                                                            );
                                                                            setGrades(updatedGrades);
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        const { value } = e.target;

                                                                        const dataToUpdate = {
                                                                            tender_id: data?.id,
                                                                            supplier_id: elem.id,
                                                                            data: { grade: value }
                                                                        };

                                                                        updateGrade(dataToUpdate);
                                                                    }}
                                                                />
                                                                {grades.find((grade) => elem.id === grade.id)
                                                                    ?.grade && <div className="grade__procent">%</div>}
                                                            </div>
                                                        </div>

                                                        {elem.manager_name && (
                                                            <div style={{ position: 'relative' }}>
                                                                <div
                                                                    className="head__manage"
                                                                    style={
                                                                        openManage === elem.id
                                                                            ? { color: '#4D5E93' }
                                                                            : {}
                                                                    }
                                                                    onClick={() =>
                                                                        setOpenManage((prev) => {
                                                                            if (elem.id === prev) {
                                                                                return null;
                                                                            } else {
                                                                                return elem.id;
                                                                            }
                                                                        })
                                                                    }
                                                                >
                                                                    <img src={dots} className="supplier__delete" />
                                                                </div>
                                                                {openManage === elem.id && (
                                                                    <div
                                                                        className="head__openManage openManage"
                                                                        style={{ top: '50px' }}
                                                                    >
                                                                        <div
                                                                            className="openManage__item"
                                                                            onClick={() => {
                                                                                setTaskId(elem.id);
                                                                                setSupplierData({
                                                                                    manager: {
                                                                                        name: elem.manager_name,
                                                                                        email: elem.email,
                                                                                        phone_number: elem.phone_number
                                                                                    },
                                                                                    company: {
                                                                                        name: elem.name
                                                                                    },
                                                                                    id: elem.id
                                                                                });
                                                                                setIsEditModal(true);
                                                                            }}
                                                                        >
                                                                            <img src={edit} />
                                                                            Edit Supplier
                                                                        </div>

                                                                        <div
                                                                            className="openManage__item cancel-tender"
                                                                            onClick={() => {
                                                                                setSupplierData(elem);
                                                                                setIsDeleteModal(true);
                                                                            }}
                                                                        >
                                                                            <Delete
                                                                                width="2em"
                                                                                style={{ margin: '0 10px 0 0' }}
                                                                            />{' '}
                                                                            Delete Supplier
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su' && !elem.approved && data.suppliers.length) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__row-btns">
                                                <div
                                                    className="task__copyemails"
                                                    onClick={copyAllEmailsInTender}
                                                    style={{ margin: '0 15px 0 0' }}
                                                >
                                                    <img src={copyImage} />
                                                    Copy all Emails
                                                </div>
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>

                                        {data.suppliers.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company_status_name)} />{' '}
                                                            {elem.company_status_name}
                                                        </div>
                                                        <div className="supplier__wrapper switcher">
                                                            Incumbent
                                                            <Switch
                                                                checked={elem.incumbent}
                                                                size="small"
                                                                className="custom-switch"
                                                                onChange={() => {
                                                                    const dataToPost = {
                                                                        tender_id: data.id,
                                                                        supplier_id: elem.id
                                                                    };
                                                                    updateIncumbentInTender(dataToPost);
                                                                }}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">
                                                                ({elem.name_prefix}) {elem.name}
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {elem.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager_name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager_name ? elem.manager_name : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem?.email ? elem?.email : ''}{' '}
                                                                    {elem?.phone_number ? elem?.phone_number : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                        <div className="supplier__grade grade">
                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {elem.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}
                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <div className="grade__icon_wrapper">
                                                                    <img src={gradeIcon} />
                                                                </div>
                                                            </Tooltip>
                                                            <div className="grade__value_wrapper disabled">
                                                                Grade: {elem.grade?.value} %
                                                            </div>
                                                        </div>
                                                        {elem.manager_name && (
                                                            <img
                                                                src={editIcon}
                                                                className="supplier__delete"
                                                                onClick={() => {
                                                                    setTaskId(elem.id);
                                                                    setSupplierData({
                                                                        manager: {
                                                                            name: elem.manager_name,
                                                                            email: elem.email,
                                                                            phone_number: elem.phone_number
                                                                        },
                                                                        company: {
                                                                            name: elem.name
                                                                        },
                                                                        id: elem.id
                                                                    });
                                                                    setIsEditModal(true);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__row-btns">
                                                <div className="task__copyemails" onClick={copyAllEmailsInTender}>
                                                    <img src={copyImage} />
                                                    Copy all Emails
                                                </div>
                                                {data?.phase_tasks?.find(
                                                    (task) => task.action === 'supplier_report_card'
                                                )?.files?.tender_representative?.[0]?.name ? (
                                                    <img
                                                        src={plus}
                                                        className="task__plus"
                                                        onClick={() => {
                                                            setIsCreateSupplierModal(true);
                                                        }}
                                                    />
                                                ) : (
                                                    <Tooltip title="To edit suppliers you need to update the Supplier Report Card first">
                                                        <div
                                                            className="task__plus"
                                                            style={{ padding: '8px 10px', cursor: 'context-menu' }}
                                                        >
                                                            <Plus fill="#E6E8F0" />
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>

                                        {data.suppliers.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company_status_name)} />{' '}
                                                            {elem.company_status_name}
                                                            {elem.status === 'shortlisted' && (
                                                                <div
                                                                    className="task-complete__title-row"
                                                                    style={{ margin: '0 0 0 20px' }}
                                                                >
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Shortlisted by School User
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {elem.status === 'rejected' && (
                                                                <div
                                                                    className="task-complete__title-row"
                                                                    style={{ margin: '0 0 0 20px' }}
                                                                >
                                                                    <div className="task-complete__decline-active">
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="supplier__wrapper switcher">
                                                            Incumbent
                                                            <Switch
                                                                checked={elem.incumbent}
                                                                size="small"
                                                                className="custom-switch"
                                                                onChange={() => {
                                                                    const dataToPost = {
                                                                        tender_id: data.id,
                                                                        supplier_id: elem.id
                                                                    };
                                                                    updateIncumbentInTender(dataToPost);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">
                                                                ({elem.name_prefix}) {elem.name}
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {elem.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager_name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager_name ? elem.manager_name : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem?.email ? elem?.email : ''}{' '}
                                                                    {elem?.phone_number ? elem?.phone_number : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}

                                                        <div className="supplier__grade grade">
                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {elem.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}
                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <div className="grade__icon_wrapper">
                                                                    <img src={gradeIcon} />
                                                                </div>
                                                            </Tooltip>
                                                            {!data?.phase_tasks?.find(
                                                                (task) => task.action === 'supplier_report_card'
                                                            )?.files?.tender_representative?.[0]?.name ? (
                                                                <Tooltip title="To edit the grade you need to update the Supplier Report Card first">
                                                                    <div className="grade__value_wrapper disabled">
                                                                        Grade: {elem.grade?.value} %
                                                                    </div>
                                                                </Tooltip>
                                                            ) : (
                                                                <div className="grade__value_wrapper">
                                                                    Grade:{' '}
                                                                    <input
                                                                        className="grade__input"
                                                                        maxLength={3}
                                                                        onInput={handleKeyPressNumbersOnly}
                                                                        value={
                                                                            grades.find((grade) => elem.id === grade.id)
                                                                                ?.grade
                                                                        }
                                                                        onChange={(e) => {
                                                                            const { value } = e.target;
                                                                            const index = grades.findIndex(
                                                                                (grade) => elem.id === grade.id
                                                                            );

                                                                            if (index !== -1) {
                                                                                const updatedGrades = grades.map(
                                                                                    (grade, i) =>
                                                                                        i === index
                                                                                            ? {
                                                                                                  ...grade,
                                                                                                  grade: value
                                                                                              }
                                                                                            : grade
                                                                                );
                                                                                setGrades(updatedGrades);
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            const { value } = e.target;

                                                                            const dataToUpdate = {
                                                                                tender_id: data?.id,
                                                                                supplier_id: elem.id,
                                                                                data: { grade: value }
                                                                            };

                                                                            updateGrade(dataToUpdate);
                                                                        }}
                                                                    />
                                                                    {grades.find((grade) => elem.id === grade.id)
                                                                        ?.grade && (
                                                                        <div className="grade__procent">%</div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        {elem.manager_name && (
                                                            <img
                                                                src={editIcon}
                                                                className="supplier__delete"
                                                                onClick={() => {
                                                                    setTaskId(elem.id);
                                                                    setSupplierData({
                                                                        manager: {
                                                                            name: elem.manager_name,
                                                                            email: elem.email,
                                                                            phone_number: elem.phone_number
                                                                        },
                                                                        company: {
                                                                            name: elem.name
                                                                        },
                                                                        id: elem.id
                                                                    });
                                                                    setIsEditModal(true);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {elem.status === 'rejected' && (
                                                        <div
                                                            className="supplier__reject-comment"
                                                            style={{ width: '98%', margin: '10px 0' }}
                                                        >
                                                            <img src={messageIcon} />
                                                            {elem.comments.su_rejected_category_name}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su' && data.suppliers.length) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((item: SupplierI) => {
                                                return (
                                                    <>
                                                        <div className="task-complete__supplier supplier supplier-SU">
                                                            <div className="supplier-SU__row">
                                                                <div className="supplier__column">
                                                                    <div className="supplier__row">
                                                                        <div className="supplier_status">
                                                                            <img
                                                                                src={statusImage(
                                                                                    item.company_status_name
                                                                                )}
                                                                            />{' '}
                                                                            {item.company_status_name}
                                                                        </div>
                                                                        {item.incumbent && (
                                                                            <div className="supplier__incumbent">
                                                                                <img src={attantion} />
                                                                                Incumbent Supplier
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="supplier-SU__name">
                                                                        ({item.name_prefix}) {item.name}
                                                                    </div>
                                                                    <div className="supplier-SU__trading_name">
                                                                        {item.trading_name}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="supplier__row"
                                                                    style={{
                                                                        justifyContent: 'end',
                                                                        position: 'relative'
                                                                    }}
                                                                >
                                                                    <div className="supplier__grade grade SU">
                                                                        <Tooltip
                                                                            color="#fff"
                                                                            key={'#fff'}
                                                                            overlayClassName="history"
                                                                            title={
                                                                                <div className="grade-history">
                                                                                    Grade History
                                                                                    {item.grade_history?.map(
                                                                                        (grade) => {
                                                                                            return (
                                                                                                <div className="grade-history__item">
                                                                                                    <div className="grade-history__title">
                                                                                                        {
                                                                                                            grade.phase_name
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="grade-history__value">
                                                                                                        {grade.grade
                                                                                                            .value_format ===
                                                                                                        'Pass'
                                                                                                            ? 'Pass'
                                                                                                            : role !==
                                                                                                                  'school_user' &&
                                                                                                              grade
                                                                                                                  .grade
                                                                                                                  .value_format}
                                                                                                        {grade?.grade
                                                                                                            ?.key !==
                                                                                                            'pass' && (
                                                                                                            <div
                                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                )}`}
                                                                                                            >
                                                                                                                {
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                }
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <div className="grade__icon_wrapper">
                                                                                <img src={gradeIcon} />
                                                                            </div>
                                                                        </Tooltip>

                                                                        <div
                                                                            className={`grade__value_wrapper ${classNameOfGrade(
                                                                                item.grade.key
                                                                            )}`}
                                                                        >
                                                                            Grade {item.grade?.key}
                                                                        </div>
                                                                    </div>
                                                                    <div className="grade__border"></div>
                                                                    <div>
                                                                        {item.status === 'new' && (
                                                                            <div className="task-complete__title-row">
                                                                                <Tooltip
                                                                                    title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__decline ${
                                                                                            data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? ''
                                                                                                : 'disable'
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                setTaskId(item.id);
                                                                                                setSelectedSupplier(
                                                                                                    item
                                                                                                );
                                                                                                if (item.incumbent) {
                                                                                                    setIsRejectIncumbentModal(
                                                                                                        true
                                                                                                    );
                                                                                                } else {
                                                                                                    setIsRejectModal(
                                                                                                        true
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Reject
                                                                                    </div>
                                                                                </Tooltip>

                                                                                <Tooltip
                                                                                    title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__approved ${
                                                                                            !data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? 'disabled-blue-button'
                                                                                                : ''
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (isLoadingShortList)
                                                                                                return;
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                shortlistFunc(item.id);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Shortlist
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                        {item.status === 'rejected' && (
                                                                            <div className="task-complete__title-row">
                                                                                <div
                                                                                    className="task-complete__decline-active"
                                                                                    style={{ margin: '0 15px 0 0' }}
                                                                                >
                                                                                    <img src={stopImage} />
                                                                                    Rejected
                                                                                </div>
                                                                                <Tooltip
                                                                                    title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__approved ${
                                                                                            !data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? 'disabled-blue-button'
                                                                                                : ''
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (isLoadingShortList)
                                                                                                return;
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                shortlistFunc(item.id);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Shortlist
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                        {item.status === 'shortlisted' && (
                                                                            <div className="task-complete__title-row">
                                                                                <Tooltip
                                                                                    title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__decline ${
                                                                                            data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? ''
                                                                                                : 'disable'
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                setTaskId(item.id);
                                                                                                setSelectedSupplier(
                                                                                                    item
                                                                                                );
                                                                                                if (item.incumbent) {
                                                                                                    setIsRejectIncumbentModal(
                                                                                                        true
                                                                                                    );
                                                                                                } else {
                                                                                                    setIsRejectModal(
                                                                                                        true
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Reject
                                                                                    </div>
                                                                                </Tooltip>

                                                                                <div className="task-complete__approved-active">
                                                                                    <img src={done} />
                                                                                    Shortlisted
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(item?.comments?.su_rejected_comment ||
                                                                item.comments?.su_rejected_category_name) && (
                                                                <div
                                                                    className="task-complete__info-wrap"
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Reason for Rejection
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            {item.comments.su_rejected_comment
                                                                                ? item.comments.su_rejected_comment
                                                                                : item.comments
                                                                                      ?.su_rejected_category_name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {openState.find((state) => state.id === item.id)?.open ? (
                                                            <div className="supplier-SU__additional">
                                                                <div className="supplier-SU__gray_row">
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            B-BBEE
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.b_bbee}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            Number of Employees
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.employees_number}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item last">
                                                                        <div className="supplier-SU__item-name">
                                                                            Annual Revenue
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.annual_revenue}
                                                                        </div>
                                                                    </div>
                                                                    <a
                                                                        href={item.website}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <div className="supplier-SU__white-btn">
                                                                            Visit Website <img src={Visit} />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                {item.conflict_of_interest && (
                                                                    <div className="supplier-SU__add-text">
                                                                        <div className="supplier-SU__item-name">
                                                                            Potential conflict of interest
                                                                        </div>
                                                                        <div className="supplier-SU__conflict">
                                                                            <img src={messageIcon} />{' '}
                                                                            {item.conflict_of_interest}
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="supplier-SU__add-text">
                                                                    <div className="supplier-SU__item-name">
                                                                        References:{' '}
                                                                    </div>
                                                                    <div className="supplier-SU__row-references">
                                                                        {item.references.map((ref) => {
                                                                            return (
                                                                                <div className="supplier-SU__reference reference">
                                                                                    <div className="reference__item">
                                                                                        {ref.entity_name}
                                                                                    </div>
                                                                                    <div className="reference__name">
                                                                                        {ref.contact_name}
                                                                                    </div>
                                                                                    <div className="reference__email">
                                                                                        {ref.email} {ref.phone_number}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="supplier-SU__show"
                                                                    onClick={() => {
                                                                        const index = openState.findIndex(
                                                                            (state) => item.id === state.id
                                                                        );

                                                                        if (index !== -1) {
                                                                            const updatedStates = openState.map(
                                                                                (state, i) =>
                                                                                    i === index
                                                                                        ? { ...state, open: false }
                                                                                        : state
                                                                            );
                                                                            setOpenState(updatedStates);
                                                                        }
                                                                    }}
                                                                >
                                                                    Hide Details{' '}
                                                                    <img
                                                                        src={arrow}
                                                                        style={{ transform: 'rotate(180deg)' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="supplier-SU__show"
                                                                onClick={() => {
                                                                    const index = openState.findIndex(
                                                                        (state) => item.id === state.id
                                                                    );

                                                                    if (index !== -1) {
                                                                        const updatedStates = openState.map(
                                                                            (state, i) =>
                                                                                i === index
                                                                                    ? { ...state, open: true }
                                                                                    : state
                                                                        );
                                                                        setOpenState(updatedStates);
                                                                    }
                                                                }}
                                                            >
                                                                Show Details <img src={arrow} />
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task__pending" key={elem.id}>
                                                <img src={pendingIcon} />
                                                Pending
                                            </div>
                                        </div>

                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((item: SupplierI) => {
                                                return (
                                                    <>
                                                        <div className="task-complete__supplier supplier supplier-SU">
                                                            <div className="supplier-SU__row">
                                                                <div className="supplier__column">
                                                                    <div className="supplier__row">
                                                                        <div className="supplier_status">
                                                                            <img
                                                                                src={statusImage(
                                                                                    item.company_status_name
                                                                                )}
                                                                            />{' '}
                                                                            {item.company_status_name}
                                                                        </div>
                                                                        {item.incumbent && (
                                                                            <div className="supplier__incumbent">
                                                                                <img src={attantion} />
                                                                                Incumbent Supplier
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="supplier-SU__name">
                                                                        ({item.name_prefix}) {item.name}
                                                                    </div>
                                                                    <div className="supplier-SU__trading_name">
                                                                        {item.trading_name}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="supplier__row"
                                                                    style={{ justifyContent: 'end' }}
                                                                >
                                                                    <div className="supplier__grade grade SU">
                                                                        <Tooltip
                                                                            color="#fff"
                                                                            key={'#fff'}
                                                                            overlayClassName="history"
                                                                            title={
                                                                                <div className="grade-history">
                                                                                    Grade History
                                                                                    {item.grade_history?.map(
                                                                                        (grade) => {
                                                                                            return (
                                                                                                <div className="grade-history__item">
                                                                                                    <div className="grade-history__title">
                                                                                                        {
                                                                                                            grade.phase_name
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="grade-history__value">
                                                                                                        {grade.grade
                                                                                                            .value_format ===
                                                                                                        'Pass'
                                                                                                            ? 'Pass'
                                                                                                            : role !==
                                                                                                                  'school_user' &&
                                                                                                              grade
                                                                                                                  .grade
                                                                                                                  .value_format}
                                                                                                        {grade?.grade
                                                                                                            ?.key !==
                                                                                                            'pass' && (
                                                                                                            <div
                                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                )}`}
                                                                                                            >
                                                                                                                {
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                }
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <div className="grade__icon_wrapper">
                                                                                <img src={gradeIcon} />
                                                                            </div>{' '}
                                                                        </Tooltip>
                                                                        <div
                                                                            className={`grade__value_wrapper ${classNameOfGrade(
                                                                                item.grade.key
                                                                            )}`}
                                                                        >
                                                                            Grade {item.grade?.key}
                                                                        </div>
                                                                    </div>
                                                                    <div className="grade__border"></div>
                                                                    <div>
                                                                        {item.status === 'rejected' && (
                                                                            <div className="task-complete__title-row">
                                                                                <div
                                                                                    className="task-complete__decline-active"
                                                                                    style={{ margin: '0 15px 0 0' }}
                                                                                >
                                                                                    <img src={stopImage} />
                                                                                    Rejected
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {item.status === 'shortlisted' && (
                                                                            <div className="task-complete__title-row">
                                                                                <div className="task-complete__approved-active">
                                                                                    <img src={done} />
                                                                                    Shortlisted
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(item?.comments?.su_rejected_comment ||
                                                                item.comments?.su_rejected_category_name) && (
                                                                <div
                                                                    className="task-complete__info-wrap"
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Reason for Rejection
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            {item.comments.su_rejected_comment
                                                                                ? item.comments.su_rejected_comment
                                                                                : item.comments
                                                                                      ?.su_rejected_category_name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {openState.find((state) => state.id === item.id)?.open ? (
                                                            <div className="supplier-SU__additional">
                                                                <div className="supplier-SU__gray_row">
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            B-BBEE
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.b_bbee}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            Number of Employees
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.employees_number}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item last">
                                                                        <div className="supplier-SU__item-name">
                                                                            Annual Revenue
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.annual_revenue}
                                                                        </div>
                                                                    </div>
                                                                    <a
                                                                        href={item.website}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <div className="supplier-SU__white-btn">
                                                                            Visit Website <img src={Visit} />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                {item.conflict_of_interest && (
                                                                    <div className="supplier-SU__add-text">
                                                                        <div className="supplier-SU__item-name">
                                                                            Potential conflict of interest
                                                                        </div>
                                                                        <div className="supplier-SU__conflict">
                                                                            <img src={messageIcon} />{' '}
                                                                            {item.conflict_of_interest}
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="supplier-SU__add-text">
                                                                    <div className="supplier-SU__item-name">
                                                                        References:{' '}
                                                                    </div>
                                                                    <div className="supplier-SU__row-references">
                                                                        {item.references.map((ref) => {
                                                                            return (
                                                                                <div className="supplier-SU__reference reference">
                                                                                    <div className="reference__item">
                                                                                        {ref.entity_name}
                                                                                    </div>
                                                                                    <div className="reference__name">
                                                                                        {ref.contact_name}
                                                                                    </div>
                                                                                    <div className="reference__email">
                                                                                        {ref.email} {ref.phone_number}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="supplier-SU__show"
                                                                    onClick={() => {
                                                                        const index = openState.findIndex(
                                                                            (state) => item.id === state.id
                                                                        );

                                                                        if (index !== -1) {
                                                                            const updatedStates = openState.map(
                                                                                (state, i) =>
                                                                                    i === index
                                                                                        ? { ...state, open: false }
                                                                                        : state
                                                                            );
                                                                            setOpenState(updatedStates);
                                                                        }
                                                                    }}
                                                                >
                                                                    Hide Details{' '}
                                                                    <img
                                                                        src={arrow}
                                                                        style={{ transform: 'rotate(180deg)' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="supplier-SU__show"
                                                                onClick={() => {
                                                                    const index = openState.findIndex(
                                                                        (state) => item.id === state.id
                                                                    );

                                                                    if (index !== -1) {
                                                                        const updatedStates = openState.map(
                                                                            (state, i) =>
                                                                                i === index
                                                                                    ? { ...state, open: true }
                                                                                    : state
                                                                        );
                                                                        setOpenState(updatedStates);
                                                                    }
                                                                }}
                                                            >
                                                                Show Details <img src={arrow} />
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                    if (elem.action === 'evaluate_3_suppliers') {
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((item: SupplierI) => {
                                                return (
                                                    <>
                                                        <div className="task-complete__supplier supplier supplier-SU">
                                                            <div className="supplier-SU__row">
                                                                <div className="supplier__column">
                                                                    <div className="supplier__row">
                                                                        <div className="supplier_status">
                                                                            <img
                                                                                src={statusImage(
                                                                                    item.company_status_name
                                                                                )}
                                                                            />{' '}
                                                                            {item.company_status_name}
                                                                        </div>
                                                                        {item.incumbent && (
                                                                            <div className="supplier__incumbent">
                                                                                <img src={attantion} />
                                                                                Incumbent Supplier
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="supplier-SU__name">
                                                                        ({item.name_prefix}) {item.name}
                                                                    </div>
                                                                    <div className="supplier-SU__trading_name">
                                                                        {item.trading_name}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="supplier__row"
                                                                    style={{
                                                                        justifyContent: 'end',
                                                                        position: 'relative'
                                                                    }}
                                                                >
                                                                    <div className="supplier__grade grade SU">
                                                                        <Tooltip
                                                                            color="#fff"
                                                                            key={'#fff'}
                                                                            overlayClassName="history"
                                                                            title={
                                                                                <div className="grade-history">
                                                                                    Grade History
                                                                                    {item.grade_history?.map(
                                                                                        (grade) => {
                                                                                            return (
                                                                                                <div className="grade-history__item">
                                                                                                    <div className="grade-history__title">
                                                                                                        {
                                                                                                            grade.phase_name
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="grade-history__value">
                                                                                                        {grade.grade
                                                                                                            .value_format ===
                                                                                                        'Pass'
                                                                                                            ? 'Pass'
                                                                                                            : role !==
                                                                                                                  'school_user' &&
                                                                                                              grade
                                                                                                                  .grade
                                                                                                                  .value_format}
                                                                                                        {grade?.grade
                                                                                                            ?.key !==
                                                                                                            'pass' && (
                                                                                                            <div
                                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                )}`}
                                                                                                            >
                                                                                                                {
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                }
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <div className="grade__icon_wrapper">
                                                                                <img src={gradeIcon} />
                                                                            </div>
                                                                        </Tooltip>

                                                                        <div
                                                                            className={`grade__value_wrapper ${classNameOfGrade(
                                                                                item.grade.key
                                                                            )}`}
                                                                        >
                                                                            Grade {item.grade?.key}
                                                                        </div>
                                                                    </div>

                                                                    <div className="grade__border"></div>
                                                                    <div>
                                                                        {item.status === 'new' && (
                                                                            <div className="task-complete__title-row">
                                                                                <Tooltip
                                                                                    title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__decline ${
                                                                                            data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? ''
                                                                                                : 'disable'
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                setTaskId(item.id);
                                                                                                setSelectedSupplier(
                                                                                                    item
                                                                                                );
                                                                                                if (item.incumbent) {
                                                                                                    setIsRejectIncumbentModal(
                                                                                                        true
                                                                                                    );
                                                                                                } else {
                                                                                                    setIsRejectModal(
                                                                                                        true
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Reject
                                                                                    </div>
                                                                                </Tooltip>

                                                                                <Tooltip
                                                                                    title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__approved ${
                                                                                            !data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? 'disabled-blue-button'
                                                                                                : ''
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (isLoadingShortList)
                                                                                                return;
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                shortlistFunc(item.id);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Shortlist
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                        {item.status === 'rejected' && (
                                                                            <div className="task-complete__title-row">
                                                                                <div
                                                                                    className="task-complete__decline-active"
                                                                                    style={{ margin: '0 15px 0 0' }}
                                                                                >
                                                                                    <img src={stopImage} />
                                                                                    Rejected
                                                                                </div>
                                                                                <Tooltip
                                                                                    title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__approved ${
                                                                                            !data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? 'disabled-blue-button'
                                                                                                : ''
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (isLoadingShortList)
                                                                                                return;
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                shortlistFunc(item.id);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Shortlist
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                        {item.status === 'shortlisted' && (
                                                                            <div className="task-complete__title-row">
                                                                                <Tooltip
                                                                                    title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__decline ${
                                                                                            data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? ''
                                                                                                : 'disable'
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                setTaskId(item.id);
                                                                                                setSelectedSupplier(
                                                                                                    item
                                                                                                );
                                                                                                if (item.incumbent) {
                                                                                                    setIsRejectIncumbentModal(
                                                                                                        true
                                                                                                    );
                                                                                                } else {
                                                                                                    setIsRejectModal(
                                                                                                        true
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Reject
                                                                                    </div>
                                                                                </Tooltip>

                                                                                <div className="task-complete__approved-active">
                                                                                    <img src={done} />
                                                                                    Shortlisted
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(item?.comments?.su_rejected_comment ||
                                                                item.comments?.su_rejected_category_name) && (
                                                                <div
                                                                    className="task-complete__info-wrap"
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Reason for Rejection
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            {item.comments.su_rejected_comment
                                                                                ? item.comments.su_rejected_comment
                                                                                : item.comments
                                                                                      ?.su_rejected_category_name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {openState.find((state) => state.id === item.id)?.open ? (
                                                            <div className="supplier-SU__additional">
                                                                <div className="supplier-SU__gray_row">
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            B-BBEE
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.b_bbee}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            Number of Employees
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.employees_number}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item last">
                                                                        <div className="supplier-SU__item-name">
                                                                            Annual Revenue
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.annual_revenue}
                                                                        </div>
                                                                    </div>
                                                                    <a
                                                                        href={item.website}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <div className="supplier-SU__white-btn">
                                                                            Visit Website <img src={Visit} />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                {item.conflict_of_interest && (
                                                                    <div className="supplier-SU__add-text">
                                                                        <div className="supplier-SU__item-name">
                                                                            Potential conflict of interest
                                                                        </div>
                                                                        <div className="supplier-SU__conflict">
                                                                            <img src={messageIcon} />{' '}
                                                                            {item.conflict_of_interest}
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="supplier-SU__add-text">
                                                                    <div className="supplier-SU__item-name">
                                                                        References:{' '}
                                                                    </div>
                                                                    <div className="supplier-SU__row-references">
                                                                        {item.references.map((ref) => {
                                                                            return (
                                                                                <div className="supplier-SU__reference reference">
                                                                                    <div className="reference__item">
                                                                                        {ref.entity_name}
                                                                                    </div>
                                                                                    <div className="reference__name">
                                                                                        {ref.contact_name}
                                                                                    </div>
                                                                                    <div className="reference__email">
                                                                                        {ref.email} {ref.phone_number}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="supplier-SU__show"
                                                                    onClick={() => {
                                                                        const index = openState.findIndex(
                                                                            (state) => item.id === state.id
                                                                        );

                                                                        if (index !== -1) {
                                                                            const updatedStates = openState.map(
                                                                                (state, i) =>
                                                                                    i === index
                                                                                        ? { ...state, open: false }
                                                                                        : state
                                                                            );
                                                                            setOpenState(updatedStates);
                                                                        }
                                                                    }}
                                                                >
                                                                    Hide Details{' '}
                                                                    <img
                                                                        src={arrow}
                                                                        style={{ transform: 'rotate(180deg)' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="supplier-SU__show"
                                                                onClick={() => {
                                                                    const index = openState.findIndex(
                                                                        (state) => item.id === state.id
                                                                    );

                                                                    if (index !== -1) {
                                                                        const updatedStates = openState.map(
                                                                            (state, i) =>
                                                                                i === index
                                                                                    ? { ...state, open: true }
                                                                                    : state
                                                                        );
                                                                        setOpenState(updatedStates);
                                                                    }
                                                                }}
                                                            >
                                                                Show Details <img src={arrow} />
                                                            </div>
                                                        )}
                                                        {/* <>
                                                            {elem?.files?.sent_su?.[0]?.name && (
                                                                <div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Received File
                                                                        </div>
                                                                    </div>{' '}
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        <div className="task-complete__value file">
                                                                            <img
                                                                                src={ReceivedFile}
                                                                                className="upload-download-icon"
                                                                            />
                                                                            {elem.files.sent_su[0].name}
                                                                        </div>
                                                                        <div
                                                                            className={`task-complete__download-btn `}
                                                                            onClick={() => {
                                                                                downloadFunc(
                                                                                    elem.files.sent_su[0].uuid,
                                                                                    elem.id
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={downloadIcon}
                                                                                className="upload-download-icon"
                                                                            />
                                                                            Download
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {elem.comments.school_user && (
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value school-user">
                                                                        <img src={messageGrey} />
                                                                        {elem.comments.school_user}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </> */}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task__pending" key={elem.id}>
                                                <img src={pendingIcon} />
                                                Pending
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((item: SupplierI) => {
                                                return (
                                                    <>
                                                        <div className="task-complete__supplier supplier supplier-SU">
                                                            <div className="supplier-SU__row">
                                                                <div className="supplier__column">
                                                                    <div className="supplier__row">
                                                                        <div className="supplier_status">
                                                                            <img
                                                                                src={statusImage(
                                                                                    item.company_status_name
                                                                                )}
                                                                            />{' '}
                                                                            {item.company_status_name}
                                                                        </div>
                                                                        {item.incumbent && (
                                                                            <div className="supplier__incumbent">
                                                                                <img src={attantion} />
                                                                                Incumbent Supplier
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="supplier-SU__name">
                                                                        ({item.name_prefix}) {item.name}
                                                                    </div>
                                                                    <div className="supplier-SU__trading_name">
                                                                        {item.trading_name}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="supplier__row"
                                                                    style={{
                                                                        justifyContent: 'end',
                                                                        position: 'relative'
                                                                    }}
                                                                >
                                                                    <div className="supplier__grade grade SU">
                                                                        {' '}
                                                                        <Tooltip
                                                                            color="#fff"
                                                                            key={'#fff'}
                                                                            overlayClassName="history"
                                                                            title={
                                                                                <div className="grade-history">
                                                                                    Grade History
                                                                                    {item.grade_history?.map(
                                                                                        (grade) => {
                                                                                            return (
                                                                                                <div className="grade-history__item">
                                                                                                    <div className="grade-history__title">
                                                                                                        {
                                                                                                            grade.phase_name
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="grade-history__value">
                                                                                                        {grade.grade
                                                                                                            .value_format ===
                                                                                                        'Pass'
                                                                                                            ? 'Pass'
                                                                                                            : role !==
                                                                                                                  'school_user' &&
                                                                                                              grade
                                                                                                                  .grade
                                                                                                                  .value_format}
                                                                                                        {grade?.grade
                                                                                                            ?.key !==
                                                                                                            'pass' && (
                                                                                                            <div
                                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                )}`}
                                                                                                            >
                                                                                                                {
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                }
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <div className="grade__icon_wrapper">
                                                                                <img src={gradeIcon} />
                                                                            </div>
                                                                        </Tooltip>
                                                                        <div
                                                                            className={`grade__value_wrapper ${classNameOfGrade(
                                                                                item.grade.key
                                                                            )}`}
                                                                        >
                                                                            Grade {item.grade?.key}
                                                                        </div>
                                                                    </div>

                                                                    <div className="grade__border"></div>
                                                                    <div>
                                                                        {item.status === 'rejected' && (
                                                                            <div className="task-complete__title-row">
                                                                                <div
                                                                                    className="task-complete__decline-active"
                                                                                    style={{ margin: '0 15px 0 0' }}
                                                                                >
                                                                                    <img src={stopImage} />
                                                                                    Rejected
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {item.status === 'shortlisted' && (
                                                                            <div className="task-complete__title-row">
                                                                                <div className="task-complete__approved-active">
                                                                                    <img src={done} />
                                                                                    Shortlisted
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(item?.comments?.su_rejected_comment ||
                                                                item.comments?.su_rejected_category_name) && (
                                                                <div
                                                                    className="task-complete__info-wrap"
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Reason for Rejection
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            {item.comments.su_rejected_comment
                                                                                ? item.comments.su_rejected_comment
                                                                                : item.comments
                                                                                      ?.su_rejected_category_name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {openState.find((state) => state.id === item.id)?.open ? (
                                                            <div className="supplier-SU__additional">
                                                                <div className="supplier-SU__gray_row">
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            B-BBEE
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.b_bbee}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            Number of Employees
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.employees_number}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item last">
                                                                        <div className="supplier-SU__item-name">
                                                                            Annual Revenue
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.annual_revenue}
                                                                        </div>
                                                                    </div>
                                                                    <a
                                                                        href={item.website}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <div className="supplier-SU__white-btn">
                                                                            Visit Website <img src={Visit} />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                {item.conflict_of_interest && (
                                                                    <div className="supplier-SU__add-text">
                                                                        <div className="supplier-SU__item-name">
                                                                            Potential conflict of interest
                                                                        </div>
                                                                        <div className="supplier-SU__conflict">
                                                                            <img src={messageIcon} />{' '}
                                                                            {item.conflict_of_interest}
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="supplier-SU__add-text">
                                                                    <div className="supplier-SU__item-name">
                                                                        References:{' '}
                                                                    </div>
                                                                    <div className="supplier-SU__row-references">
                                                                        {item.references.map((ref) => {
                                                                            return (
                                                                                <div className="supplier-SU__reference reference">
                                                                                    <div className="reference__item">
                                                                                        {ref.entity_name}
                                                                                    </div>
                                                                                    <div className="reference__name">
                                                                                        {ref.contact_name}
                                                                                    </div>
                                                                                    <div className="reference__email">
                                                                                        {ref.email} {ref.phone_number}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="supplier-SU__show"
                                                                    onClick={() => {
                                                                        const index = openState.findIndex(
                                                                            (state) => item.id === state.id
                                                                        );

                                                                        if (index !== -1) {
                                                                            const updatedStates = openState.map(
                                                                                (state, i) =>
                                                                                    i === index
                                                                                        ? { ...state, open: false }
                                                                                        : state
                                                                            );
                                                                            setOpenState(updatedStates);
                                                                        }
                                                                    }}
                                                                >
                                                                    Hide Details{' '}
                                                                    <img
                                                                        src={arrow}
                                                                        style={{ transform: 'rotate(180deg)' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="supplier-SU__show"
                                                                onClick={() => {
                                                                    const index = openState.findIndex(
                                                                        (state) => item.id === state.id
                                                                    );

                                                                    if (index !== -1) {
                                                                        const updatedStates = openState.map(
                                                                            (state, i) =>
                                                                                i === index
                                                                                    ? { ...state, open: true }
                                                                                    : state
                                                                        );
                                                                        setOpenState(updatedStates);
                                                                    }
                                                                }}
                                                            >
                                                                Show Details <img src={arrow} />
                                                            </div>
                                                        )}
                                                        {/* <>
                                                            {elem?.files?.sent_su?.[0]?.name && (
                                                                <div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Received File
                                                                        </div>
                                                                    </div>{' '}
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        <div className="task-complete__value file">
                                                                            <img
                                                                                src={ReceivedFile}
                                                                                className="upload-download-icon"
                                                                            />
                                                                            {elem.files.sent_su[0].name}
                                                                        </div>
                                                                        <div
                                                                            className={`task-complete__download-btn `}
                                                                            onClick={() => {
                                                                                downloadFunc(
                                                                                    elem.files.sent_su[0].uuid,
                                                                                    elem.id
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={downloadIcon}
                                                                                className="upload-download-icon"
                                                                            />
                                                                            Download
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {elem.comments.school_user && (
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value school-user">
                                                                        <img src={messageGrey} />
                                                                        {elem.comments.school_user}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </> */}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                        } else {
                            if (elem.status === 'pending_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green

                              `}
                                        ref={ref}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>

                                        {data.suppliers.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company_status_name)} />{' '}
                                                            {elem.company_status_name}
                                                        </div>
                                                        {elem.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">
                                                                ({elem.name_prefix}) {elem.name}
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {elem.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager_name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager_name ? elem.manager_name : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem?.email ? elem?.email : ''}{' '}
                                                                    {elem?.phone_number ? elem?.phone_number : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}

                                                        <div className="supplier__grade grade">
                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {elem.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}
                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <div className="grade__icon_wrapper">
                                                                    <img src={gradeIcon} />
                                                                </div>
                                                            </Tooltip>
                                                            <div className="grade__value_wrapper">
                                                                Grade:{' '}
                                                                <input
                                                                    className="grade__input"
                                                                    maxLength={3}
                                                                    onInput={handleKeyPressNumbersOnly}
                                                                    value={
                                                                        grades.find((grade) => elem.id === grade.id)
                                                                            ?.grade
                                                                    }
                                                                    onChange={(e) => {
                                                                        const { value } = e.target;
                                                                        const index = grades.findIndex(
                                                                            (grade) => elem.id === grade.id
                                                                        );

                                                                        if (index !== -1) {
                                                                            const updatedGrades = grades.map(
                                                                                (grade, i) =>
                                                                                    i === index
                                                                                        ? { ...grade, grade: value }
                                                                                        : grade
                                                                            );
                                                                            setGrades(updatedGrades);
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        const { value } = e.target;

                                                                        const dataToUpdate = {
                                                                            tender_id: data?.id,
                                                                            supplier_id: elem.id,
                                                                            data: { grade: value }
                                                                        };

                                                                        updateGrade(dataToUpdate);
                                                                    }}
                                                                />
                                                                {grades.find((grade) => elem.id === grade.id)
                                                                    ?.grade && <div className="grade__procent">%</div>}
                                                            </div>
                                                        </div>

                                                        <img
                                                            src={editIcon}
                                                            className="supplier__delete"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setSupplierData({
                                                                    manager: {
                                                                        name: elem.manager_name,
                                                                        email: elem.email,
                                                                        phone_number: elem.phone_number
                                                                    },
                                                                    company: {
                                                                        name: elem.name
                                                                    },
                                                                    id: elem.id
                                                                });
                                                                setIsEditModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`} ref={ref}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task__pending" key={elem.id}>
                                                <img src={pendingIcon} />
                                                Pending
                                            </div>
                                        </div>

                                        {data.suppliers.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company_status_name)} />{' '}
                                                            {elem.company_status_name}
                                                        </div>
                                                        {elem.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">
                                                                ({elem.name_prefix}) {elem.name}
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {elem.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager_name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager_name ? elem.manager_name : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem?.email ? elem?.email : ''}{' '}
                                                                    {elem?.phone_number ? elem?.phone_number : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}

                                                        <div className="supplier__grade grade">
                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {elem.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}
                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <div className="grade__icon_wrapper">
                                                                    <img src={gradeIcon} />
                                                                </div>
                                                            </Tooltip>
                                                            <div className="grade__value_wrapper disabled">
                                                                Grade: {elem.grade?.value} %
                                                            </div>
                                                        </div>

                                                        <img
                                                            src={editIcon}
                                                            className="supplier__delete"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setSupplierData({
                                                                    manager: {
                                                                        name: elem.manager_name,
                                                                        email: elem.email,
                                                                        phone_number: elem.phone_number
                                                                    },
                                                                    company: {
                                                                        name: elem.name
                                                                    },
                                                                    id: elem.id
                                                                });
                                                                setIsEditModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>

                                        {data.suppliers.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company_status_name)} />{' '}
                                                            {elem.company_status_name}
                                                            {elem.status === 'shortlisted' && (
                                                                <div
                                                                    className="task-complete__title-row"
                                                                    style={{ margin: '0 0 0 20px' }}
                                                                >
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Shortlisted by School User
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {elem.status === 'rejected' && (
                                                                <div
                                                                    className="task-complete__title-row"
                                                                    style={{ margin: '0 0 0 20px' }}
                                                                >
                                                                    <div className="task-complete__decline-active">
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {elem.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">
                                                                ({elem.name_prefix}) {elem.name}
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {elem.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager_name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager_name ? elem.manager_name : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem?.email ? elem?.email : ''}{' '}
                                                                    {elem?.phone_number ? elem?.phone_number : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                        <div className="supplier__grade grade">
                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {elem.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}
                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <div className="grade__icon_wrapper">
                                                                    <img src={gradeIcon} />
                                                                </div>
                                                            </Tooltip>
                                                            {!data?.phase_tasks?.find(
                                                                (task) => task.action === 'supplier_report_card'
                                                            )?.files?.tender_representative?.[0]?.name ? (
                                                                <Tooltip title="To edit the grade you need to update the Supplier Report Card first">
                                                                    <div className="grade__value_wrapper disabled">
                                                                        Grade: {elem.grade?.value} %
                                                                    </div>
                                                                </Tooltip>
                                                            ) : (
                                                                <div className="grade__value_wrapper">
                                                                    Grade:{' '}
                                                                    <input
                                                                        className="grade__input"
                                                                        maxLength={3}
                                                                        onInput={handleKeyPressNumbersOnly}
                                                                        value={
                                                                            grades.find((grade) => elem.id === grade.id)
                                                                                ?.grade
                                                                        }
                                                                        onChange={(e) => {
                                                                            const { value } = e.target;
                                                                            const index = grades.findIndex(
                                                                                (grade) => elem.id === grade.id
                                                                            );

                                                                            if (index !== -1) {
                                                                                const updatedGrades = grades.map(
                                                                                    (grade, i) =>
                                                                                        i === index
                                                                                            ? {
                                                                                                  ...grade,
                                                                                                  grade: value
                                                                                              }
                                                                                            : grade
                                                                                );
                                                                                setGrades(updatedGrades);
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            const { value } = e.target;

                                                                            const dataToUpdate = {
                                                                                tender_id: data?.id,
                                                                                supplier_id: elem.id,
                                                                                data: { grade: value }
                                                                            };

                                                                            updateGrade(dataToUpdate);
                                                                        }}
                                                                    />
                                                                    {grades.find((grade) => elem.id === grade.id)
                                                                        ?.grade && (
                                                                        <div className="grade__procent">%</div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        {elem.manager_name && (
                                                            <img
                                                                src={editIcon}
                                                                className="supplier__delete"
                                                                onClick={() => {
                                                                    setTaskId(elem.id);
                                                                    setSupplierData({
                                                                        manager: {
                                                                            name: elem.manager_name,
                                                                            email: elem.email,
                                                                            phone_number: elem.phone_number
                                                                        },
                                                                        company: {
                                                                            name: elem.name
                                                                        },
                                                                        id: elem.id
                                                                    });
                                                                    setIsEditModal(true);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {elem.status === 'rejected' && (
                                                        <div
                                                            className="supplier__reject-comment"
                                                            style={{ width: '98%', margin: '10px 0' }}
                                                        >
                                                            <img src={messageIcon} />
                                                            {elem.comments.su_rejected_category_name}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        }
                    }
                    if (elem.action === 'evaluate_4_suppliers') {
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((item: SupplierI) => {
                                                return (
                                                    <>
                                                        <div className="task-complete__supplier supplier supplier-SU">
                                                            <div className="supplier-SU__row">
                                                                <div className="supplier__column">
                                                                    <div className="supplier__row">
                                                                        <div className="supplier_status">
                                                                            <img
                                                                                src={statusImage(
                                                                                    item.company_status_name
                                                                                )}
                                                                            />{' '}
                                                                            {item.company_status_name}
                                                                        </div>
                                                                        {item.incumbent && (
                                                                            <div className="supplier__incumbent">
                                                                                <img src={attantion} />
                                                                                Incumbent Supplier
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="supplier-SU__name">
                                                                        ({item.name_prefix}) {item.name}
                                                                    </div>
                                                                    <div className="supplier-SU__trading_name">
                                                                        {item.trading_name}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="supplier__row"
                                                                    style={{
                                                                        justifyContent: 'end',
                                                                        position: 'relative'
                                                                    }}
                                                                >
                                                                    <div className="supplier__grade grade SU">
                                                                        <Tooltip
                                                                            color="#fff"
                                                                            key={'#fff'}
                                                                            overlayClassName="history"
                                                                            title={
                                                                                <div className="grade-history">
                                                                                    Grade History
                                                                                    {item.grade_history?.map(
                                                                                        (grade) => {
                                                                                            return (
                                                                                                <div className="grade-history__item">
                                                                                                    <div className="grade-history__title">
                                                                                                        {
                                                                                                            grade.phase_name
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="grade-history__value">
                                                                                                        {grade.grade
                                                                                                            .value_format ===
                                                                                                        'Pass'
                                                                                                            ? 'Pass'
                                                                                                            : role !==
                                                                                                                  'school_user' &&
                                                                                                              grade
                                                                                                                  .grade
                                                                                                                  .value_format}
                                                                                                        {grade?.grade
                                                                                                            ?.key !==
                                                                                                            'pass' && (
                                                                                                            <div
                                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                )}`}
                                                                                                            >
                                                                                                                {
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                }
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <div className="grade__icon_wrapper">
                                                                                <img src={gradeIcon} />
                                                                            </div>
                                                                        </Tooltip>

                                                                        <div
                                                                            className={`grade__value_wrapper ${classNameOfGrade(
                                                                                item.grade.key
                                                                            )}`}
                                                                        >
                                                                            Grade {item.grade?.key}
                                                                        </div>
                                                                    </div>
                                                                    <div className="grade__border"></div>
                                                                    <div>
                                                                        {item.status === 'new' && (
                                                                            <div className="task-complete__title-row">
                                                                                <Tooltip
                                                                                    title="To Reject or Aword the suppliers, you need download Supplier Report Card  and Letter of Intent template first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__decline ${
                                                                                            data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? ''
                                                                                                : 'disable'
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                setTaskId(item.id);
                                                                                                setSelectedSupplier(
                                                                                                    item
                                                                                                );
                                                                                                if (item.incumbent) {
                                                                                                    setIsRejectIncumbentModal(
                                                                                                        true
                                                                                                    );
                                                                                                } else {
                                                                                                    setIsRejectModal(
                                                                                                        true
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Reject
                                                                                    </div>
                                                                                </Tooltip>

                                                                                <Tooltip
                                                                                    title="To Reject or Aword the suppliers, you need download Supplier Report Card  and Letter of Intent template first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__approved ${
                                                                                            !data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? 'disabled-blue-button'
                                                                                                : ''
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (isLoadingShortList)
                                                                                                return;
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                setIsSuppAwardModal(
                                                                                                    true
                                                                                                );
                                                                                                setSelectedSupplier(
                                                                                                    item
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Award
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                        {item.status === 'rejected' && (
                                                                            <div className="task-complete__title-row">
                                                                                <div
                                                                                    className="task-complete__decline-active"
                                                                                    style={{ margin: '0 15px 0 0' }}
                                                                                >
                                                                                    <img src={stopImage} />
                                                                                    Rejected
                                                                                </div>
                                                                                <Tooltip
                                                                                    title="To Reject or Aword the suppliers, you need download Supplier Report Card  and Letter of Intent template first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__approved ${
                                                                                            !data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? 'disabled-blue-button'
                                                                                                : ''
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (isLoadingShortList)
                                                                                                return;
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                setIsSuppAwardModal(
                                                                                                    true
                                                                                                );
                                                                                                setSelectedSupplier(
                                                                                                    item
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Award
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                        {item.status === 'shortlisted' && (
                                                                            <div className="task-complete__title-row">
                                                                                <Tooltip
                                                                                    title="To Reject or Aword the suppliers, you need download Supplier Report Card  and Letter of Intent template first!"
                                                                                    overlayClassName={
                                                                                        !data?.phase_tasks?.[0]
                                                                                            ?.file_downloaded
                                                                                            ? 'custom-tooltip-disabled'
                                                                                            : 'custom-tooltip'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`task-complete__decline ${
                                                                                            data?.phase_tasks?.[0]
                                                                                                ?.file_downloaded
                                                                                                ? ''
                                                                                                : 'disable'
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                data?.phase_tasks?.[0]
                                                                                                    ?.file_downloaded
                                                                                            ) {
                                                                                                setTaskId(item.id);
                                                                                                setSelectedSupplier(
                                                                                                    item
                                                                                                );
                                                                                                if (item.incumbent) {
                                                                                                    setIsRejectIncumbentModal(
                                                                                                        true
                                                                                                    );
                                                                                                } else {
                                                                                                    setIsRejectModal(
                                                                                                        true
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Reject
                                                                                    </div>
                                                                                </Tooltip>

                                                                                <div className="task-complete__approved-active">
                                                                                    <img src={done} />
                                                                                    Awarded
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(item?.comments?.su_rejected_comment ||
                                                                item.comments?.su_rejected_category_name) && (
                                                                <div
                                                                    className="task-complete__info-wrap"
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Reason for Rejection
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            {item.comments.su_rejected_comment
                                                                                ? item.comments.su_rejected_comment
                                                                                : item.comments
                                                                                      ?.su_rejected_category_name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {openState.find((state) => state.id === item.id)?.open ? (
                                                            <div className="supplier-SU__additional">
                                                                <div className="supplier-SU__gray_row">
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            B-BBEE
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.b_bbee}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            Number of Employees
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.employees_number}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item last">
                                                                        <div className="supplier-SU__item-name">
                                                                            Annual Revenue
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.annual_revenue}
                                                                        </div>
                                                                    </div>
                                                                    <a
                                                                        href={item.website}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <div className="supplier-SU__white-btn">
                                                                            Visit Website <img src={Visit} />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                {item.conflict_of_interest && (
                                                                    <div className="supplier-SU__add-text">
                                                                        <div className="supplier-SU__item-name">
                                                                            Potential conflict of interest
                                                                        </div>
                                                                        <div className="supplier-SU__conflict">
                                                                            <img src={messageIcon} />{' '}
                                                                            {item.conflict_of_interest}
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="supplier-SU__add-text">
                                                                    <div className="supplier-SU__item-name">
                                                                        References:{' '}
                                                                    </div>
                                                                    <div className="supplier-SU__row-references">
                                                                        {item.references.map((ref) => {
                                                                            return (
                                                                                <div className="supplier-SU__reference reference">
                                                                                    <div className="reference__item">
                                                                                        {ref.entity_name}
                                                                                    </div>
                                                                                    <div className="reference__name">
                                                                                        {ref.contact_name}
                                                                                    </div>
                                                                                    <div className="reference__email">
                                                                                        {ref.email} {ref.phone_number}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="supplier-SU__show"
                                                                    onClick={() => {
                                                                        const index = openState.findIndex(
                                                                            (state) => item.id === state.id
                                                                        );

                                                                        if (index !== -1) {
                                                                            const updatedStates = openState.map(
                                                                                (state, i) =>
                                                                                    i === index
                                                                                        ? { ...state, open: false }
                                                                                        : state
                                                                            );
                                                                            setOpenState(updatedStates);
                                                                        }
                                                                    }}
                                                                >
                                                                    Hide Details{' '}
                                                                    <img
                                                                        src={arrow}
                                                                        style={{ transform: 'rotate(180deg)' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="supplier-SU__show"
                                                                onClick={() => {
                                                                    const index = openState.findIndex(
                                                                        (state) => item.id === state.id
                                                                    );

                                                                    if (index !== -1) {
                                                                        const updatedStates = openState.map(
                                                                            (state, i) =>
                                                                                i === index
                                                                                    ? { ...state, open: true }
                                                                                    : state
                                                                        );
                                                                        setOpenState(updatedStates);
                                                                    }
                                                                }}
                                                            >
                                                                Show Details <img src={arrow} />
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task__pending" key={elem.id}>
                                                <img src={pendingIcon} />
                                                Pending
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((item: SupplierI) => {
                                                return (
                                                    <>
                                                        <div className="task-complete__supplier supplier supplier-SU">
                                                            <div className="supplier-SU__row">
                                                                <div className="supplier__column">
                                                                    <div className="supplier__row">
                                                                        <div className="supplier_status">
                                                                            <img
                                                                                src={statusImage(
                                                                                    item.company_status_name
                                                                                )}
                                                                            />{' '}
                                                                            {item.company_status_name}
                                                                        </div>
                                                                        {item.incumbent && (
                                                                            <div className="supplier__incumbent">
                                                                                <img src={attantion} />
                                                                                Incumbent Supplier
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="supplier-SU__name">
                                                                        ({item.name_prefix}) {item.name}
                                                                    </div>
                                                                    <div className="supplier-SU__trading_name">
                                                                        {item.trading_name}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="supplier__row"
                                                                    style={{
                                                                        justifyContent: 'end',
                                                                        position: 'relative'
                                                                    }}
                                                                >
                                                                    <div className="supplier__grade grade SU">
                                                                        <Tooltip
                                                                            color="#fff"
                                                                            key={'#fff'}
                                                                            overlayClassName="history"
                                                                            title={
                                                                                <div className="grade-history">
                                                                                    Grade History
                                                                                    {item.grade_history?.map(
                                                                                        (grade) => {
                                                                                            return (
                                                                                                <div className="grade-history__item">
                                                                                                    <div className="grade-history__title">
                                                                                                        {
                                                                                                            grade.phase_name
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="grade-history__value">
                                                                                                        {grade.grade
                                                                                                            .value_format ===
                                                                                                        'Pass'
                                                                                                            ? 'Pass'
                                                                                                            : role !==
                                                                                                                  'school_user' &&
                                                                                                              grade
                                                                                                                  .grade
                                                                                                                  .value_format}
                                                                                                        {grade?.grade
                                                                                                            ?.key !==
                                                                                                            'pass' && (
                                                                                                            <div
                                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                )}`}
                                                                                                            >
                                                                                                                {
                                                                                                                    grade
                                                                                                                        ?.grade
                                                                                                                        ?.key
                                                                                                                }
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <div className="grade__icon_wrapper">
                                                                                <img src={gradeIcon} />
                                                                            </div>
                                                                        </Tooltip>
                                                                        <div
                                                                            className={`grade__value_wrapper ${classNameOfGrade(
                                                                                item.grade.key
                                                                            )}`}
                                                                        >
                                                                            Grade {item.grade?.key}
                                                                        </div>
                                                                    </div>
                                                                    <div className="grade__border"></div>
                                                                    <div>
                                                                        {item.status === 'rejected' && (
                                                                            <div className="task-complete__title-row">
                                                                                <div
                                                                                    className="task-complete__decline-active"
                                                                                    style={{ margin: '0 15px 0 0' }}
                                                                                >
                                                                                    <img src={stopImage} />
                                                                                    Rejected
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {item.status === 'shortlisted' && (
                                                                            <div className="task-complete__title-row">
                                                                                <div className="task-complete__approved-active">
                                                                                    <img src={done} />
                                                                                    Awarded
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(item?.comments?.su_rejected_comment ||
                                                                item.comments?.su_rejected_category_name) && (
                                                                <div
                                                                    className="task-complete__info-wrap"
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Reason for Rejection
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            {item.comments.su_rejected_comment
                                                                                ? item.comments.su_rejected_comment
                                                                                : item.comments
                                                                                      ?.su_rejected_category_name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {openState.find((state) => state.id === item.id)?.open ? (
                                                            <div className="supplier-SU__additional">
                                                                <div className="supplier-SU__gray_row">
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            B-BBEE
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.b_bbee}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item">
                                                                        <div className="supplier-SU__item-name">
                                                                            Number of Employees
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.employees_number}
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier-SU__item last">
                                                                        <div className="supplier-SU__item-name">
                                                                            Annual Revenue
                                                                        </div>
                                                                        <div className="supplier-SU__item-value">
                                                                            {item.annual_revenue}
                                                                        </div>
                                                                    </div>
                                                                    <a
                                                                        href={item.website}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <div className="supplier-SU__white-btn">
                                                                            Visit Website <img src={Visit} />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                {item.conflict_of_interest && (
                                                                    <div className="supplier-SU__add-text">
                                                                        <div className="supplier-SU__item-name">
                                                                            Potential conflict of interest
                                                                        </div>
                                                                        <div className="supplier-SU__conflict">
                                                                            <img src={messageIcon} />{' '}
                                                                            {item.conflict_of_interest}
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="supplier-SU__add-text">
                                                                    <div className="supplier-SU__item-name">
                                                                        References:{' '}
                                                                    </div>
                                                                    <div className="supplier-SU__row-references">
                                                                        {item.references.map((ref) => {
                                                                            return (
                                                                                <div className="supplier-SU__reference reference">
                                                                                    <div className="reference__item">
                                                                                        {ref.entity_name}
                                                                                    </div>
                                                                                    <div className="reference__name">
                                                                                        {ref.contact_name}
                                                                                    </div>
                                                                                    <div className="reference__email">
                                                                                        {ref.email} {ref.phone_number}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="supplier-SU__show"
                                                                    onClick={() => {
                                                                        const index = openState.findIndex(
                                                                            (state) => item.id === state.id
                                                                        );

                                                                        if (index !== -1) {
                                                                            const updatedStates = openState.map(
                                                                                (state, i) =>
                                                                                    i === index
                                                                                        ? { ...state, open: false }
                                                                                        : state
                                                                            );
                                                                            setOpenState(updatedStates);
                                                                        }
                                                                    }}
                                                                >
                                                                    Hide Details{' '}
                                                                    <img
                                                                        src={arrow}
                                                                        style={{ transform: 'rotate(180deg)' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="supplier-SU__show"
                                                                onClick={() => {
                                                                    const index = openState.findIndex(
                                                                        (state) => item.id === state.id
                                                                    );

                                                                    if (index !== -1) {
                                                                        const updatedStates = openState.map(
                                                                            (state, i) =>
                                                                                i === index
                                                                                    ? { ...state, open: true }
                                                                                    : state
                                                                        );
                                                                        setOpenState(updatedStates);
                                                                    }
                                                                }}
                                                            >
                                                                Show Details <img src={arrow} />
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                        } else {
                            if (
                                elem.status === 'pending_tr' &&
                                data?.phase_tasks?.[0].action !== 'evaluate_4_report_card'
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                            
                              `}
                                        ref={ref}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>

                                        {data.suppliers.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company_status_name)} />{' '}
                                                            {elem.company_status_name}
                                                        </div>
                                                        {elem.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">
                                                                ({elem.name_prefix}) {elem.name}
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {elem.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager_name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager_name ? elem.manager_name : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem?.email ? elem?.email : ''}{' '}
                                                                    {elem?.phone_number ? elem?.phone_number : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}

                                                        <Tooltip
                                                            color="#fff"
                                                            key={'#fff'}
                                                            overlayClassName="history"
                                                            title={
                                                                <div className="grade-history">
                                                                    Grade History
                                                                    {elem.grade_history?.map((grade) => {
                                                                        return (
                                                                            <div className="grade-history__item">
                                                                                <div className="grade-history__title">
                                                                                    {grade.phase_name}
                                                                                </div>
                                                                                <div className="grade-history__value">
                                                                                    {grade.grade.value_format === 'Pass'
                                                                                        ? 'Pass'
                                                                                        : role !== 'school_user' &&
                                                                                          grade.grade.value_format}
                                                                                    {grade?.grade?.key !== 'pass' && (
                                                                                        <div
                                                                                            className={`grade-history__key ${classNameOfGrade(
                                                                                                grade?.grade?.key
                                                                                            )}`}
                                                                                        >
                                                                                            {grade?.grade?.key}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            }
                                                        >
                                                            <img
                                                                src={gradeHistory}
                                                                style={{ margin: '0 10px 0 0 ', cursor: 'pointer' }}
                                                            />
                                                        </Tooltip>

                                                        <img
                                                            src={editIcon}
                                                            className="supplier__delete"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setSupplierData({
                                                                    manager: {
                                                                        name: elem.manager_name,
                                                                        email: elem.email,
                                                                        phone_number: elem.phone_number
                                                                    },
                                                                    company: {
                                                                        name: elem.name
                                                                    },
                                                                    id: elem.id
                                                                });
                                                                setIsEditModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'pending_tr' &&
                                data?.phase_tasks?.[0].action === 'evaluate_4_report_card'
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                                            
                              `}
                                        ref={ref}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>

                                        {data.suppliers.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company_status_name)} />{' '}
                                                            {elem.company_status_name}
                                                        </div>
                                                        {elem.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">
                                                                ({elem.name_prefix}) {elem.name}
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {elem.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager_name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager_name ? elem.manager_name : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem?.email ? elem?.email : ''}{' '}
                                                                    {elem?.phone_number ? elem?.phone_number : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}

                                                        <div className="supplier__grade grade">
                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {elem.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}
                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <div className="grade__icon_wrapper">
                                                                    <img src={gradeIcon} />
                                                                </div>
                                                            </Tooltip>
                                                            <div className="grade__value_wrapper">
                                                                Grade:{' '}
                                                                <input
                                                                    className="grade__input"
                                                                    maxLength={3}
                                                                    onInput={handleKeyPressNumbersOnly}
                                                                    value={
                                                                        grades.find((grade) => elem.id === grade.id)
                                                                            ?.grade
                                                                    }
                                                                    onChange={(e) => {
                                                                        const { value } = e.target;
                                                                        const index = grades.findIndex(
                                                                            (grade) => elem.id === grade.id
                                                                        );

                                                                        if (index !== -1) {
                                                                            const updatedGrades = grades.map(
                                                                                (grade, i) =>
                                                                                    i === index
                                                                                        ? { ...grade, grade: value }
                                                                                        : grade
                                                                            );
                                                                            setGrades(updatedGrades);
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        const { value } = e.target;

                                                                        const dataToUpdate = {
                                                                            tender_id: data?.id,
                                                                            supplier_id: elem.id,
                                                                            data: { grade: value }
                                                                        };

                                                                        updateGrade(dataToUpdate);
                                                                    }}
                                                                />
                                                                {grades.find((grade) => elem.id === grade.id)
                                                                    ?.grade && <div className="grade__procent">%</div>}
                                                            </div>
                                                        </div>

                                                        <img
                                                            src={editIcon}
                                                            className="supplier__delete"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setSupplierData({
                                                                    manager: {
                                                                        name: elem.manager_name,
                                                                        email: elem.email,
                                                                        phone_number: elem.phone_number
                                                                    },
                                                                    company: {
                                                                        name: elem.name
                                                                    },
                                                                    id: elem.id
                                                                });
                                                                setIsEditModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'pending_su' &&
                                data?.phase_tasks?.[0].action === 'evaluate_4_report_card'
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                                            
                              `}
                                        ref={ref}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>

                                        {data.suppliers.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company_status_name)} />{' '}
                                                            {elem.company_status_name}
                                                        </div>
                                                        {elem.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">
                                                                ({elem.name_prefix}) {elem.name}
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {elem.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager_name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager_name ? elem.manager_name : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem?.email ? elem?.email : ''}{' '}
                                                                    {elem?.phone_number ? elem?.phone_number : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}

                                                        <div className="supplier__grade grade">
                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {elem.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}
                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <div className="grade__icon_wrapper">
                                                                    <img src={gradeIcon} />
                                                                </div>
                                                            </Tooltip>
                                                            <div className="grade__value_wrapper disabled">
                                                                Grade: {elem.grade?.value} %
                                                            </div>
                                                        </div>

                                                        <img
                                                            src={editIcon}
                                                            className="supplier__delete"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setSupplierData({
                                                                    manager: {
                                                                        name: elem.manager_name,
                                                                        email: elem.email,
                                                                        phone_number: elem.phone_number
                                                                    },
                                                                    company: {
                                                                        name: elem.name
                                                                    },
                                                                    id: elem.id
                                                                });
                                                                setIsEditModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>

                                        {data.suppliers.map((item) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(item.company_status_name)} />{' '}
                                                            {item.company_status_name}
                                                            {item.status === 'shortlisted' && (
                                                                <div
                                                                    className="task-complete__title-row"
                                                                    style={{ margin: '0 0 0 20px' }}
                                                                >
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Awarded by School User
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item.status === 'rejected' && (
                                                                <div
                                                                    className="task-complete__title-row"
                                                                    style={{ margin: '0 0 0 20px' }}
                                                                >
                                                                    <div className="task-complete__decline-active">
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {item.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">
                                                                ({item.name_prefix}) {item.name}
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {item.trading_name}
                                                            </div>
                                                        </div>
                                                        {item.manager_name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {item.manager_name ? item.manager_name : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {item?.email ? item?.email : ''}{' '}
                                                                    {item?.phone_number ? item?.phone_number : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                        <div className="supplier__grade grade">
                                                            <Tooltip
                                                                color="#fff"
                                                                key={'#fff'}
                                                                overlayClassName="history"
                                                                title={
                                                                    <div className="grade-history">
                                                                        Grade History
                                                                        {item.grade_history?.map((grade) => {
                                                                            return (
                                                                                <div className="grade-history__item">
                                                                                    <div className="grade-history__title">
                                                                                        {grade.phase_name}
                                                                                    </div>
                                                                                    <div className="grade-history__value">
                                                                                        {grade.grade.value_format ===
                                                                                        'Pass'
                                                                                            ? 'Pass'
                                                                                            : role !== 'school_user' &&
                                                                                              grade.grade.value_format}
                                                                                        {grade?.grade?.key !==
                                                                                            'pass' && (
                                                                                            <div
                                                                                                className={`grade-history__key ${classNameOfGrade(
                                                                                                    grade?.grade?.key
                                                                                                )}`}
                                                                                            >
                                                                                                {grade?.grade?.key}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <div className="grade__icon_wrapper">
                                                                    <img src={gradeIcon} />
                                                                </div>
                                                            </Tooltip>
                                                            {!data?.phase_tasks?.find(
                                                                (task) => task.action === 'evaluate_4_report_card'
                                                            )?.files?.tender_representative?.[0]?.name ? (
                                                                <Tooltip title="To edit the grade you need to update the Supplier Report Card first">
                                                                    <div className="grade__value_wrapper disabled">
                                                                        Grade: {item.grade?.value} %
                                                                    </div>
                                                                </Tooltip>
                                                            ) : (
                                                                <div className="grade__value_wrapper">
                                                                    Grade:{' '}
                                                                    <input
                                                                        className="grade__input"
                                                                        maxLength={3}
                                                                        onInput={handleKeyPressNumbersOnly}
                                                                        value={
                                                                            grades.find((grade) => item.id === grade.id)
                                                                                ?.grade
                                                                        }
                                                                        onChange={(e) => {
                                                                            const { value } = e.target;
                                                                            const index = grades.findIndex(
                                                                                (grade) => item.id === grade.id
                                                                            );

                                                                            if (index !== -1) {
                                                                                const updatedGrades = grades.map(
                                                                                    (grade, i) =>
                                                                                        i === index
                                                                                            ? {
                                                                                                  ...grade,
                                                                                                  grade: value
                                                                                              }
                                                                                            : grade
                                                                                );
                                                                                setGrades(updatedGrades);
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            const { value } = e.target;

                                                                            const dataToUpdate = {
                                                                                tender_id: data?.id,
                                                                                supplier_id: item.id,
                                                                                data: { grade: value }
                                                                            };

                                                                            updateGrade(dataToUpdate);
                                                                        }}
                                                                    />
                                                                    {grades.find((grade) => item.id === grade.id)
                                                                        ?.grade && (
                                                                        <div className="grade__procent">%</div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        {item.manager_name && (
                                                            <img
                                                                src={editIcon}
                                                                className="supplier__delete"
                                                                onClick={() => {
                                                                    setTaskId(item.id);
                                                                    setSupplierData({
                                                                        manager: {
                                                                            name: item.manager_name,
                                                                            email: item.email,
                                                                            phone_number: item.phone_number
                                                                        },
                                                                        company: {
                                                                            name: item.name
                                                                        },
                                                                        id: item.id
                                                                    });
                                                                    setIsEditModal(true);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {item.status === 'rejected' && (
                                                        <div
                                                            className="supplier__reject-comment"
                                                            style={{ width: '98%', margin: '10px 0' }}
                                                        >
                                                            <img src={messageIcon} />
                                                            {item.comments.su_rejected_category_name}
                                                        </div>
                                                    )}
                                                    <>
                                                        {item?.files?.su_evaluate_4_awarded_file?.name && (
                                                            <div style={{ width: '98%', margin: '0 0 12px 0' }}>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">
                                                                        Received File
                                                                    </div>
                                                                </div>{' '}
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center'
                                                                    }}
                                                                >
                                                                    <div className="task-complete__value file">
                                                                        <img
                                                                            src={ReceivedFile}
                                                                            className="upload-download-icon"
                                                                        />
                                                                        {item?.files?.su_evaluate_4_awarded_file?.name}
                                                                    </div>
                                                                    <div
                                                                        className={`task-complete__download-btn `}
                                                                        onClick={() => {
                                                                            downloadFunc(
                                                                                item?.files?.su_evaluate_4_awarded_file
                                                                                    .uuid,
                                                                                setTrigger,
                                                                                item.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={downloadIcon}
                                                                            className="upload-download-icon"
                                                                        />
                                                                        Download
                                                                    </div>
                                                                </div>
                                                                {item.comments.su_awarded_comment && (
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value school-user">
                                                                            <img src={messageGrey} />
                                                                            {item.comments.su_awarded_comment}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        }
                    }
                })}

            {supplierModal && (
                <AddSuppliersModal
                    openModal={supplierModal}
                    setOpenModal={setSupplierModal}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    data={data}
                />
            )}
            {isEditModal && (
                <EditSupplierModal
                    openModal={isEditModal}
                    setOpenModal={setIsEditModal}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    SupplierData={SupplierData}
                    data={data}
                />
            )}

            {isDeleteModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteSupplierFunc}>
                                {isLoadingDelete ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__title">Remove supplier from the tender?</div>
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to remove the <b>{SupplierData?.name}</b> from the tender supplier
                            list?{' '}
                        </div>
                    </div>
                </Modal>
            )}
            {isCreateSupplierModal && (
                <AddConfirmedSuppliersModal
                    openModal={isCreateSupplierModal}
                    setOpenModal={setIsCreateSupplierModal}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    data={data}
                />
            )}
            {isRejectModal && (
                <RejectSupplierModal
                    openModal={isRejectModal}
                    setOpenModal={setIsRejectModal}
                    setTrigger={setTrigger}
                    selectedSupplier={selectedSupplier}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                />
            )}
            {isRejectIncumbentModal && (
                <RejectIncumbentSupplier
                    openModal={isRejectIncumbentModal}
                    setOpenModal={setIsRejectIncumbentModal}
                    setTrigger={setTrigger}
                    selectedSupplier={selectedSupplier}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setIsRejectModal={setIsRejectModal}
                />
            )}
            {isEditModal && (
                <EditSupplierModal
                    openModal={isEditModal}
                    setOpenModal={setIsEditModal}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    SupplierData={SupplierData}
                    data={data}
                />
            )}

            {isSuppAwardModal && (
                <AwardSuppliserModal
                    openModal={isSuppAwardModal}
                    setOpenModal={setIsSuppAwardModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    selectedSupplier={selectedSupplier}
                    specialFormats={['doc', 'docx', 'pdf']}
                />
            )}
        </>
    );
};
