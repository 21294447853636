import { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { Loader } from '../Loader/Loader';
import { SupplierI } from '../../pages/TenderViewPage/types';
import close from '../../assets/RoundedClose.svg';
import * as Yup from 'yup';
import { useRejectSupplierMutation } from '../../api/tenders';
import { FloatSelect } from '../Fields/FloatSelect';
import { useGetSchoolTypesQuery } from '../../api/common';
import { FloatTextArea } from '../Fields/FloatTextArea';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    selectedSupplier: SupplierI | null;
    data: any;
    taskId: number | null;
    messageApi: any;
}

const validationSchema = Yup.object().shape({
    reason: Yup.object().required('This is a required field'),
    comment: Yup.string()
        .required('This is a required field')
        .test('comment', 'This is a required field', (value) => {
            const passwordRegex = /[^\s]/;
            return passwordRegex.test(value);
        })
});

export const RejectSupplierModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    taskId,
    setTrigger,
    selectedSupplier,
    data,
    messageApi
}) => {
    const [rejectSupplier, { data: rejectedData, isLoading, isSuccess }] = useRejectSupplierMutation();
    const types = {};
    const { data: dataSchoolTypes } = useGetSchoolTypesQuery(types);
    const [reason, setReason] = useState<any>({});
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (data && dataSchoolTypes) {
            if (data.phase === 'evaluate_1_2') {
                setOptions(
                    dataSchoolTypes?.data?.evaluate_1_2_supplier_rejections.map((elem: any) => ({
                        key: elem.key,
                        name: elem.value
                    }))
                );
            }
            if (data.phase === 'evaluate_3') {
                setOptions(
                    dataSchoolTypes?.data?.evaluate_3_supplier_rejections.map((elem: any) => ({
                        key: elem.key,
                        name: elem.value
                    }))
                );
            }
            if (data.phase === 'evaluate_4') {
                setOptions(
                    dataSchoolTypes?.data?.evaluate_4_supplier_rejections.map((elem: any) => ({
                        key: elem.key,
                        name: elem.value
                    }))
                );
            }
        }
    }, [dataSchoolTypes, data]);

    const [form] = Form.useForm();

    useEffect(() => {
        if (isSuccess) {
            setOpenModal(false);
            setTrigger((prev: boolean) => !prev);
            messageApi?.success(rejectedData?.message);
        }
    }, [isSuccess]);

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                const dataToPost = {
                    tenderId: data.id,
                    supplierId: taskId,
                    data: {
                        category: values.reason.key,
                        comment: values.reason.key === 'other' ? values.comment : ''
                    }
                };
                rejectSupplier(dataToPost);
            })
            .catch((err) => console.log('err', err));
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    setOpenModal(false);
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            block
                            key="submit"
                            type="primary"
                            onClick={() => {
                                if (isLoading) return;
                                handleFormSubmit();
                            }}
                        >
                            {isLoading ? <Loader /> : 'Confirm'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">
                            <div className="modal__nametitle">
                                Reject{' '}
                                <b>
                                    ({selectedSupplier?.name_prefix}) {selectedSupplier?.name}
                                </b>
                                ?
                            </div>
                        </div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__title">
                            We encourage you to provide feedback to suppliers to help them improve and perform better in
                            future tenders.{' '}
                        </div>
                        <div className="main__attention">
                            <b>Note: </b> Selected reason will be shared with the rejected suppliers
                        </div>
                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item
                                name="reason"
                                rules={yupSync('reason', validationSchema, true)}
                                style={{ margin: '0 0 30px 0 ' }}
                            >
                                <FloatSelect
                                    required={false}
                                    floatLabel={'Reason for Rejection'}
                                    err={false}
                                    setSearch={() => {}}
                                    options={options}
                                    onChange={(value: any) => setReason(value)}
                                    search={''}
                                    form={form}
                                    hasError={() => !!form.getFieldError(['reason']).length}
                                    type="common"
                                />
                            </Form.Item>
                            {reason?.key === 'other' && (
                                <Form.Item name="comment" rules={yupSync('comment', validationSchema, true)}>
                                    <FloatTextArea
                                        required={false}
                                        maxLength={200}
                                        floatLabel={'Reason for Rejection'}
                                        onChange={() => {}}
                                        hasError={() => !!form.getFieldError(['comment']).length}
                                        type="common"
                                    />
                                </Form.Item>
                            )}
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};
