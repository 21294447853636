import { FC, useEffect } from 'react';
import { Button, message, Modal } from 'antd';
import close from '../../assets/RoundedClose.svg';
import { useAddSuppliersToTheTenderMutation, useSendInvitesToSuppliersMutation } from '../../api/tenders';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { Loader } from '../Loader/Loader';
import Unverified from '../../assets/Unverified.svg';
import Verified from '../../assets/Verified.svg';
import attantion from '../../assets/point.svg';

import './Modals.scss';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    data: tenderDataProps;
    selectedRowKeys: number[];
    allSuppliers: any;
    setOpenAddSupplierModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectedConfirmedSuppliersModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    setTrigger,
    data,
    selectedRowKeys,
    allSuppliers,
    setOpenAddSupplierModal
}) => {
    const [addSuppliersToTheTender, { data: invitesData, isSuccess: isSuccessSendInvites, isLoading }] =
        useAddSuppliersToTheTenderMutation();

    useEffect(() => {
        if (isSuccessSendInvites) {
            setTrigger((prev: boolean) => !prev);
            setOpenModal(false);
            setOpenAddSupplierModal(false);
            message?.success(invitesData.message);
        }
    }, [isSuccessSendInvites]);

    const statusImage = (status: string) => {
        if (status === 'Unverified') {
            return Unverified;
        }
        if (status === 'Verified') {
            return Verified;
        }
    };

    const handleFormSubmit = () => {
        const inviteData = {
            tender_id: data.id,
            data: {
                ids: selectedRowKeys
            }
        };
        addSuppliersToTheTender(inviteData);
    };

    return (
        <>
            <Modal
                destroyOnClose
                className={`modal`}
                centered
                open={openModal}
                onCancel={() => {
                    setOpenModal(false);
                }}
                closeIcon={<img src={close} />}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button block key="back" onClick={() => setOpenModal(false)} className="cancel">
                            Cancel
                        </Button>
                        <Button
                            form="schoolForm"
                            type="primary"
                            block
                            onClick={handleFormSubmit}
                            disabled={selectedRowKeys.length ? false : true}
                        >
                            {isLoading ? <Loader /> : 'Add to Tender'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header addsuppliermodal">
                        <div className="modal__title">Add Suppliers</div>
                    </div>
                    <div className="modal__main main" style={{ padding: '15px 56px 43px 56px' }}>
                        <div className="main__supplierts-selected">
                            Are you sure you want to add these suppliers to the Tender?{' '}
                        </div>
                        <div className="main__supplier-list supplier-list">
                            {allSuppliers.map((elem: any) => {
                                if (selectedRowKeys.includes(elem.id)) {
                                    return (
                                        <div className="supplier-list__item">
                                            <div style={{ width: '55%' }}>
                                                <div className="supplier-list__companyname">{elem.company_name}</div>
                                                <div className="supplier-list__tradingname">{elem.trading_name}</div>
                                            </div>
                                            {elem.incumbent && (
                                                <div className="supplier-list__incumbent">
                                                    <img src={attantion} />
                                                    Incumbent
                                                </div>
                                            )}
                                            <div className="supplier_status" style={{ padding: '4px 10px' }}>
                                                <img src={statusImage(elem.status_name)} />
                                                {elem.status_name}
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
