import { Button, Modal } from 'antd';
import { taskProps, tenderDataProps } from '../../pages/TenderViewPage/types';
import close from '../../assets/RoundedClose.svg';
import TenderMandat from '../../assets/TenderMandat.svg';
import Questionnaire from '../../assets/Define Phase Questionnaire.svg';
import TenderClosingImage from '../../assets/TenderClosingImage.svg';
import SuppDocIcon from '../../assets/SuppDocIcon.svg';
import MultiAwardIcon from '../../assets/MultiAward.svg';
import task from '../../assets/task.svg';
import CloseTender from '../../assets/CloseTender.svg';
import done from '../../assets/Done, Check.svg';
import SupplierListIcon from '../../assets/SupplierListIcon.svg';
import Pitch from '../../assets/Pitch.svg';
import { Loader } from '../Loader/Loader';
import i from '../../assets/i.svg';

interface Props {
    confirmModal: boolean;
    setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
    moveFunc: () => void;
    nameOfNextPhase: () => any;
    dataTender: tenderDataProps;
}

export const NextPhaseModal = ({
    confirmModal,
    setConfirmModal,
    isLoading,
    moveFunc,
    nameOfNextPhase,
    dataTender
}: Props) => {
    console.log('nameOfNextPhase()', nameOfNextPhase());
    return (
        <Modal
            className="confirmModal"
            centered
            open={confirmModal}
            width={660}
            closeIcon={<img src={close} onClick={() => setConfirmModal(false)} />}
            footer={
                <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                    <Button key="back" onClick={() => setConfirmModal(false)} block className="cancel">
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        style={{ width: '100%' }}
                        onClick={() => {
                            if (!isLoading) {
                                moveFunc();
                            }
                        }}
                    >
                        {isLoading ? (
                            <Loader />
                        ) : (
                            ` ${nameOfNextPhase() === 'Close' ? 'Close Tender' : `Move to ${nameOfNextPhase()}`}`
                        )}
                    </Button>
                </div>
            }
        >
            <div className="modal-confirm">
                <div className="modal-confirm__title">
                    {nameOfNextPhase() === 'Design'
                        ? 'Confirm moving to Design phase'
                        : nameOfNextPhase() === 'Close'
                        ? 'Confirm closing tender'
                        : `Ready to Move to ${nameOfNextPhase()} phase`}
                </div>
                <div className="modal-confirm__subtitle">
                    Check your Tasks one more time and make sure that everything is fine
                </div>
                {nameOfNextPhase() === 'Design' && (
                    <div className="note" style={{ margin: '15px 0 0 0' }}>
                        <img src={i} />
                        <div>
                            By moving to the Design phase, suppliers will be notified of this tender opportunity and
                            invited to register. The tender mandate will also be shared with them. <br />
                            <br />
                            <b>Note:</b> Once the opportunity is announced, no further changes can be made in the Define
                            phase.
                        </div>
                    </div>
                )}
                {nameOfNextPhase() === 'Evaluation Gate 3' && (
                    <div className="note" style={{ margin: '15px 0 0 0' }}>
                        <img src={i} />
                        <div>
                            The following notifications will be sent out to:
                            <br />
                            <br /> <b>1. Rejected Suppliers</b> – Notified they were not shortlisted.
                            <br /> <b>2. Shortlisted Suppliers</b> – Informed of campus tour dates and required actions
                            (participant details, pitch date selection, presentation uploads, and commercials
                            submission).
                            <br />
                            <b> 3. The School</b> – Informed of selected campus tour dates and pitch slots.
                        </div>
                    </div>
                )}
                <div className="tasks__list" style={{ minHeight: '100px' }}>
                    {dataTender?.phase_tasks.map((elem: taskProps) => {
                        if (elem.action === 'school_deposit') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title ">
                                        <img src={task} />
                                        {elem?.name}
                                    </div>

                                    <div className="task__btn-wrap">
                                        <span className="task__approved-active">
                                            <img src={done} />
                                            Received
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                        if (elem.action === 'define_tender_mandate') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title">
                                        <img src={TenderMandat} />
                                        {elem?.name}
                                    </div>

                                    <div className="task__btn-wrap">
                                        <span className="task__approved-active">
                                            <img src={done} />
                                            Approved
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                        if (elem.action === 'define_phase_questionnaire') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title ">
                                        <img src={Questionnaire} />
                                        <div className="inNextPhaseModal">{elem?.name}</div>
                                    </div>

                                    <div className="task__btn-wrap">
                                        <span className="task__approved-active " style={{ width: '235px' }}>
                                            <img src={done} />
                                            Approved by School User
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                        if (elem.action === 'define_supporting_document') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title">
                                        <img src={SuppDocIcon} />
                                        {elem?.name}
                                    </div>

                                    <div className="task__btn-wrap">
                                        <span className="task__approved-active">
                                            <img src={done} />
                                            Approved
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                        if (elem.action === 'design_document') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title">
                                        <img src={SuppDocIcon} />
                                        <div className="inNextPhaseModal">{elem?.name}</div>
                                    </div>

                                    <div className="task__btn-wrap">
                                        <span className="task__approved-active" style={{ width: '235px' }}>
                                            <img src={done} />
                                            Approved by School User
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                        if (elem.action === 'launch_closing_date') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title">
                                        <img src={TenderClosingImage} />
                                        <div className="inNextPhaseModal">{elem?.name}</div>
                                    </div>
                                </div>
                            );
                        }
                        if (elem.action === 'supplier_report_card' || elem.action === 'evaluate_4_report_card') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title">
                                        <img src={TenderMandat} />
                                        <div className="inNextPhaseModal">{elem?.name}</div>
                                    </div>
                                    <span className="task__approved-active">
                                        <img src={done} />
                                        Uploaded
                                    </span>
                                </div>
                            );
                        }
                        if (elem.action === 'evaluate_1_2_suppliers' || elem.action === 'evaluate_4_suppliers') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title">
                                        <img src={SupplierListIcon} />
                                        <div className="inNextPhaseModal">{elem?.name}</div>
                                    </div>
                                    <span className="task__approved-active">
                                        <img src={done} />
                                        Reviewed by School User
                                    </span>
                                </div>
                            );
                        }
                        if (elem.action === 'supplier_pitch_availability_form') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title">
                                        <img src={Pitch} />
                                        <div className="inNextPhaseModal" style={{ width: '300px' }}>
                                            {elem?.name}
                                        </div>
                                    </div>
                                    <span className="task__approved-active">
                                        <img src={done} />
                                        Received{' '}
                                    </span>
                                </div>
                            );
                        }
                        if (elem.action === 'evaluate_3_suppliers') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title">
                                        <img src={SupplierListIcon} />
                                        <div className="inNextPhaseModal">{elem?.name}</div>
                                    </div>
                                    <span className="task__approved-active">
                                        <img src={done} />
                                        Reviewed by School User
                                    </span>
                                </div>
                            );
                        }
                        if (elem.action === 'evaluate_4_multi_award') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title">
                                        <img src={MultiAwardIcon} />
                                        <div className="inNextPhaseModal">{elem?.name}</div>
                                    </div>
                                    <span className="task__approved-active">
                                        <img src={done} />
                                        Provided by School User
                                    </span>
                                </div>
                            );
                        }
                        if (elem.action === 'award_close_tender_form') {
                            return (
                                <div className="tasks__task task">
                                    <div className="task__title">
                                        <img src={CloseTender} />
                                        <div className="inNextPhaseModal">{elem?.name}</div>
                                    </div>
                                    <span className="task__approved-active">
                                        <img src={done} />
                                        Approved
                                    </span>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </Modal>
    );
};
