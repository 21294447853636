import { FC, Key, SetStateAction, useEffect, useState } from 'react';
import { Button, Modal, Table } from 'antd';
import close from '../../assets/RoundedClose.svg';
import {
    useLazyGetConfirmedSuppliersQuery,
    useLazyGetNotInvitedSuppliersQuery,
    useSendInvitesToSuppliersMutation
} from '../../api/tenders';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { Loader } from '../Loader/Loader';
import InputSearch from '../Fields/InputSearch';
import { Supplier } from '../../api/types/user';
import { useTableDataSourceWithHooks } from '../Table/useTableDataPropsWithHooks';
import { TableRowSelection } from 'antd/es/table/interface';
import Unverified from '../../assets/Unverified.svg';
import Verified from '../../assets/Verified.svg';
import './Modals.scss';
import { InviteSuppliersModal } from './InviteSuppliersModal';
import { useAppSelector } from '../../hooks';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    data: tenderDataProps;
}

export const AddSuppliersModal: FC<ModalProps> = ({ openModal, setOpenModal, messageApi, setTrigger, data }) => {
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [getNotInvitedSuppliers, { data: notInvitedSuppliers, isLoading }] = useLazyGetNotInvitedSuppliersQuery();
    const [getConfirmedSuppliers, { data: confirmedSuppliers, isLoading: isLoadingConfirmedSuppliers }] =
        useLazyGetConfirmedSuppliersQuery();
    const [sendInvites, { data: invitesData, isSuccess: isSuccessSendInvites }] = useSendInvitesToSuppliersMutation();
    const [search, setSearch] = useState('');
    const [openInvitedSuppliersModal, setOpenInvitedSuppliersModal] = useState(false);
    const { role } = useAppSelector((state) => state.profileReducer);

    useEffect(() => {
        const PostData = {
            tender_id: data?.id,
            filter: { search: search }
        };
        if (role === 'onboarding_representative') {
            getNotInvitedSuppliers(PostData);
        }
        if (role === 'tender_representative') {
            getConfirmedSuppliers(PostData);
        }
    }, [search]);

    useEffect(() => {
        if (isSuccessSendInvites) {
            setTrigger((prev: boolean) => !prev);
            setOpenModal(false);
            messageApi?.success(invitesData.message);
        }
    }, [isSuccessSendInvites]);

    const statusImage = (status: string) => {
        if (status === 'Unverified') {
            return Unverified;
        }
        if (status === 'Verified') {
            return Verified;
        }
    };

    const handleFormSubmit = () => {
        const inviteData = {
            tender_id: data.id,
            data: {
                ids: selectedRowKeys
            }
        };
        sendInvites(inviteData);
    };

    const { tableProps } = useTableDataSourceWithHooks<Supplier>({
        columns: [
            {
                dataIndex: 'company_name',
                title: 'Company Name',
                sorter: false,
                width: 60
            },
            {
                dataIndex: 'provinces',
                title: 'Province',
                sorter: false,
                width: 60,
                render: (province: any) => (
                    <div>
                        {province?.map((elem: any) => (
                            <div>{elem.name}</div>
                        ))}
                    </div>
                )
            },
            {
                dataIndex: 'status',
                title: 'Supplier Status',
                sorter: false,
                width: 70,
                render: (_status: string, record: any) => (
                    <div className="supplier_status">
                        <img src={statusImage(record.status_name)} /> {record.status_name}
                    </div>
                )
            }
        ],
        rowKey: 'id',
        data: {
            rows: notInvitedSuppliers?.data,
            loading: isLoading
        },
        paginationConfig: { defaultPageSize: 10, showSizeChanger: false, position: ['bottomCenter'] },
        onQueryVariableChange: (options) => {}
    });

    const rowSelection: TableRowSelection<Supplier> = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRows: Supplier[]) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        columnWidth: 35
    };

    return (
        <>
            <Modal
                destroyOnClose
                className={`modal`}
                centered
                open={openModal}
                onCancel={() => {
                    setOpenModal(false);
                }}
                closeIcon={<img src={close} />}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button block key="back" onClick={() => setOpenModal(false)} className="cancel">
                            Cancel
                        </Button>
                        <Button
                            form="schoolForm"
                            type="primary"
                            block
                            onClick={() => setOpenInvitedSuppliersModal(true)}
                            disabled={selectedRowKeys.length ? false : true}
                        >
                            {isLoading ? <Loader /> : 'Next'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header addsuppliermodal">
                        <div className="modal__title">Add Supplier</div>
                    </div>
                    <div className="modal__main main" style={{ padding: '15px 56px 43px 56px' }}>
                        <InputSearch handleChange={setSearch} placeholder={'Search by Company Name, Province '} />
                        <div className="main__supplierts-selected">{selectedRowKeys.length} Suppliers selected</div>
                        <div className="main__supplier-table">
                            <Table<Supplier>
                                {...tableProps}
                                rowSelection={rowSelection}
                                scroll={{ y: '70vh' }}
                                dataSource={notInvitedSuppliers?.data}
                                pagination={false}
                                rowClassName="add_supplier_row"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                className={`confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
            {openInvitedSuppliersModal && (
                <InviteSuppliersModal
                    openModal={openInvitedSuppliersModal}
                    setOpenModal={setOpenInvitedSuppliersModal}
                    setTrigger={setTrigger}
                    data={data}
                    selectedRowKeys={selectedRowKeys}
                    allSuppliers={notInvitedSuppliers?.data}
                    setOpenAddSupplierModal={setOpenModal}
                />
            )}
        </>
    );
};
