import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps, IconComponentProps } from '@ant-design/icons/lib/components/Icon';

type IconProps = Pick<IconComponentProps, 'onClick'>;

const DeleteBinSvg = ({ fill, width = '1em', style }: { fill: string; width?: string; style?: any }) => (
    <svg style={style} width={width} height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Interface, Essential/Trash, Delete, Bin">
            <g id="Group">
                <g id="Group_2">
                    <path
                        id="Path"
                        d="M4.16675 5.83317H15.8334"
                        stroke={fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Path_2"
                        d="M15 5.8335V15.0002C15 15.921 14.2542 16.6668 13.3333 16.6668H6.66667C5.74583 16.6668 5 15.921 5 15.0002V5.8335"
                        stroke={fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Path_3"
                        d="M12.5 3.12516H7.5"
                        stroke={fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Path_4"
                        d="M8.33341 9.1665V13.3332"
                        stroke={fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Path_5"
                        d="M11.6667 9.1665V13.3332"
                        stroke={fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const Delete = (props: Partial<CustomIconComponentProps & IconProps>) => (
    <Icon component={() => <DeleteBinSvg fill={props.fill || 'currentColor'} />} {...props} />
);

export default Delete;
