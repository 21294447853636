import { FC, useEffect, useState } from 'react';
import { Button, Divider, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { useDeclineTaskMutation, useUpdateTaskMutation } from '../../api/tenders';
import { Loader } from '../Loader/Loader';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import Person from '../../assets/Person.svg';
import close from '../../assets/RoundedClose.svg';
import Delete from '../Icons/Delete';

import * as Yup from 'yup';
import { FloatInput } from '../Fields/FloatInput';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    type?: string;
    data?: tenderDataProps;
    taskId?: number | null | string;
}

interface commentData {
    comment: string | null;
}

export const CampusTourModal: FC<ModalProps> = ({ openModal, setOpenModal, type, data, setTrigger, taskId }) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [form] = Form.useForm();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [suppData, setSuppData] = useState<any>([]);
    const [updateTask, { isSuccess, isLoading }] = useUpdateTaskMutation();

    useEffect(() => {
        if (isSuccess) {
            setOpenModal(false);
            setTrigger((prev) => !prev);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (data?.suppliers) {
            const array = data?.suppliers.map((elem) => ({
                id: elem.id,
                name_prefix: elem.name_prefix,
                name: elem.name,
                trading_name: elem.trading_name,
                job_title: { value: elem.payload.campus_representatives?.[0]?.job_title ?? '', error: '' },
                full_name: { value: elem.payload.campus_representatives?.[0]?.name ?? '', error: '' },
                job_title2: { value: elem.payload.campus_representatives?.[1]?.job_title ?? '', error: '' },
                full_name2: { value: elem.payload.campus_representatives?.[1]?.name ?? '', error: '' },
                isRepresentative: { value: elem.payload.campus_representatives?.[1]?.job_title ? true : false }
            }));
            setSuppData(array);
        }
    }, [data?.suppliers]);

    const validateSuppData = (data: any[]) => {
        let isFormValid = true;

        const updatedData = data.map((item) => {
            const updatedJobTitle = {
                ...item.job_title,
                error: item.job_title.value.trim() === ''
            };

            const updatedFullName = {
                ...item.full_name,
                error: item.full_name.value.trim() === ''
            };

            const updatedJobTitle2 = {
                ...item.job_title2,
                error: item.isRepresentative.value && item.job_title2.value.trim() === ''
            };

            const updatedFullName2 = {
                ...item.full_name2,
                error: item.isRepresentative.value && item.full_name2.value.trim() === ''
            };

            if (updatedJobTitle.error || updatedFullName.error || updatedJobTitle2.error || updatedFullName2.error) {
                isFormValid = false;
            }

            return {
                ...item,
                job_title: updatedJobTitle,
                full_name: updatedFullName,
                job_title2: updatedJobTitle2,
                full_name2: updatedFullName2
            };
        });

        return {
            updatedData,
            isFormValid
        };
    };

    const handleFormSubmit = () => {
        const { updatedData, isFormValid } = validateSuppData(suppData);
        setSuppData(updatedData);

        if (isFormValid) {
            const dataToUpdate = {
                ids: {
                    tender_id: data?.id,
                    task_id: data?.phase_tasks?.find((elem) => elem.action === 'campus_tour_attendee_details')?.id
                },
                data: {
                    method: 'PUT',
                    representatives: suppData.reduce((acc: any, item: any) => {
                        const representativesArray = [
                            {
                                job_title: item.job_title.value,
                                name: item.full_name.value
                            },
                            ...(item.isRepresentative.value
                                ? [
                                      {
                                          job_title: item.job_title2.value,
                                          name: item.full_name2.value
                                      }
                                  ]
                                : [])
                        ];

                        acc[item.id] = representativesArray;
                        return acc;
                    }, {})
                }
            };
            if (isLoading) return;
            updateTask(dataToUpdate);
        }
    };

    const handleInputChange = (index: number, field: string, value: string | boolean) => {
        setSuppData((prevSuppData: any) =>
            prevSuppData.map((item: any, i: number) =>
                i === index ? { ...item, [field]: { ...item[field], value, error: '' } } : item
            )
        );
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched()) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Campus Tour Attendee Details</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__subtitle">Provide information about supplier participants’</div>
                        <Form form={form} onFinish={handleFormSubmit}>
                            {suppData?.map((elem: any, index: number) => {
                                return (
                                    <>
                                        <div className="campus-tour-item">
                                            <div className="campus-tour-item__header">
                                                <div className="campus-tour-item__header__name">
                                                    ({elem.name_prefix}) {elem.name}
                                                </div>
                                                <div className="campus-tour-item__header__tr-name">
                                                    {elem.trading_name}
                                                </div>
                                            </div>
                                            <Divider style={{ margin: '0px 0' }} />
                                            <div className="campus-tour-item__main">
                                                <div className="campus-tour-item__main__title">Representative</div>
                                                <div className="main__row">
                                                    <Form.Item
                                                        className={`${
                                                            screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                        }`}
                                                    >
                                                        <FloatInput
                                                            floatLabel="Job title"
                                                            className="input"
                                                            maxLength={60}
                                                            value={elem.job_title.value}
                                                            onChange={(e) =>
                                                                handleInputChange(index, 'job_title', e.target.value)
                                                            }
                                                            hasError={() => elem.job_title.error}
                                                        />
                                                        {elem.job_title.error && (
                                                            <div className="error-text">This is a required filed</div>
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={`${
                                                            screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                        }`}
                                                    >
                                                        <FloatInput
                                                            floatLabel="Full Name"
                                                            className="input"
                                                            maxLength={60}
                                                            value={elem.full_name.value}
                                                            onChange={(e) =>
                                                                handleInputChange(index, 'full_name', e.target.value)
                                                            }
                                                            hasError={() => elem.full_name.error}
                                                        />
                                                        {elem.full_name.error && (
                                                            <div className="error-text">This is a required filed</div>
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <Divider style={{ margin: '0px 0' }} />

                                            {elem.isRepresentative.value ? (
                                                <div className="campus-tour-item__main">
                                                    <div className="campus-tour-item__main__title">
                                                        Representative{' '}
                                                        <div
                                                            onClick={() => {
                                                                handleInputChange(index, 'isRepresentative', false);
                                                            }}
                                                        >
                                                            <Delete fill="#E80A0F" style={{ cursor: 'pointer' }} />
                                                        </div>
                                                    </div>
                                                    <div className="main__row">
                                                        <Form.Item
                                                            className={`${
                                                                screenWidth > 600
                                                                    ? 'small-input-wrapper'
                                                                    : 'input-wrapper'
                                                            }`}
                                                        >
                                                            <FloatInput
                                                                floatLabel="Job title"
                                                                className="input"
                                                                maxLength={60}
                                                                value={elem.job_title2.value}
                                                                onChange={(e) =>
                                                                    handleInputChange(
                                                                        index,
                                                                        'job_title2',
                                                                        e.target.value
                                                                    )
                                                                }
                                                                hasError={() => elem.job_title2.error}
                                                            />
                                                            {elem.job_title2.error && (
                                                                <div className="error-text">
                                                                    This is a required filed
                                                                </div>
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item
                                                            className={`${
                                                                screenWidth > 600
                                                                    ? 'small-input-wrapper'
                                                                    : 'input-wrapper'
                                                            }`}
                                                        >
                                                            <FloatInput
                                                                floatLabel="Full Name"
                                                                className="input"
                                                                maxLength={60}
                                                                value={elem.full_name2.value}
                                                                onChange={(e) =>
                                                                    handleInputChange(
                                                                        index,
                                                                        'full_name2',
                                                                        e.target.value
                                                                    )
                                                                }
                                                                hasError={() => elem.full_name2.error}
                                                            />
                                                            {elem.full_name2.error && (
                                                                <div className="error-text">
                                                                    This is a required filed
                                                                </div>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className="campus-tour-item__footer"
                                                    onClick={() => {
                                                        handleInputChange(index, 'isRepresentative', true);
                                                    }}
                                                >
                                                    <img src={Person} />
                                                    Add Representative
                                                </div>
                                            )}
                                        </div>
                                        <Divider />
                                    </>
                                );
                            })}
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal
                className={`${type === 'view' || type === 'view_pending' ? 'view' : ''} confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
