import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';

export const tendersApi = createApi({
    reducerPath: 'tendersApi',
    baseQuery,
    // TODO: add types for endpoints
    endpoints: (builder) => ({
        getAreas: builder.query<any, void>({
            query: () => ({
                url: `/admin/tenders/expense-areas`,
                method: 'GET'
            })
        }),
        createArea: builder.mutation({
            query: (data) => {
                return {
                    url: '/admin/tenders/expense-areas',
                    method: 'POST',
                    formData: true,
                    body: data
                };
            }
        }),
        updateArea: builder.mutation({
            query: (data) => {
                return {
                    url: `/admin/tenders/expense-areas/${data?.id}`,
                    method: 'PUT',
                    formData: true,
                    body: data.data
                };
            }
        }),
        deleteArea: builder.mutation({
            query: (id) => {
                return {
                    url: `/admin/tenders/expense-areas/${id}`,
                    method: 'Delete'
                };
            }
        }),
        createTender: builder.mutation({
            query: (data) => {
                return {
                    url: '/admin/tenders',
                    method: 'POST',
                    body: data
                };
            }
        }),
        getTender: builder.query<any, any>({
            query: (id) => ({
                url: `/tenders/${id}`,
                method: 'GET'
            })
        }),
        updateTender: builder.mutation({
            query: (data) => {
                return {
                    url: `/admin/tenders/${data?.id}`,
                    method: 'PUT',
                    formData: true,
                    body: data.data
                };
            }
        }),
        updateDeadline: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.id}/update-deadline`,
                    method: 'PUT',
                    formData: true,
                    body: data.data
                };
            }
        }),
        updateTaskDeadline: builder.mutation({
            query: (data) => {
                return {
                    url: `tenders/${data.id}/tasks/update-deadline`,
                    method: 'PUT',
                    formData: true,
                    body: data.data
                };
            }
        }),
        completeTask: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.tenderId}/tasks/${data.taskId}/complete`,
                    method: 'PUT',
                    formData: true
                };
            }
        }),
        approveTask: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.tenderId}/tasks/${data.taskId}/approve`,
                    method: 'PUT'
                };
            }
        }),
        declineTask: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.ids.tenderId}/tasks/${data.ids.taskId}/decline`,
                    method: 'PUT',
                    body: data.data
                };
            }
        }),
        sendTask: builder.mutation({
            query: (data) => {
                return {
                    url: `tenders/${data.id}/tasks/send`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        moveToNextPhase: builder.mutation({
            query: (id) => {
                return {
                    url: `/tenders/${id}/update-phase`,
                    method: 'PUT'
                };
            }
        }),
        UpdateTask: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.ids.tender_id}/tasks/${data.ids.task_id}`,
                    method: 'PUT',
                    body: data.data
                };
            }
        }),
        createSupportingDocument: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.ids.tenderId}/tasks`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        sendActivities: builder.mutation({
            query: (data) => {
                return {
                    url: `tenders/${data.tenderId}/tasks/${data.taskId}/activities`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        deleteFile: builder.mutation({
            query: (data) => {
                return {
                    url: `tenders/${data.tenderId}/tasks/${data.taskId}/files/${data.file}`,
                    method: 'Delete'
                };
            }
        }),
        deleteComment: builder.mutation({
            query: (data) => {
                return {
                    url: `tenders/${data.tenderId}/tasks/${data.taskId}`,
                    method: 'Delete'
                };
            }
        }),
        deleteSupportDoc: builder.mutation({
            query: (data) => {
                return {
                    url: `tenders/${data.tenderId}/tasks/${data.taskId}`,
                    method: 'Delete'
                };
            }
        }),
        downloadFile: builder.query<any, any>({
            query: (data) => ({
                url: `tenders/${data.tenderId}/tasks/${data.taskId}/files/${data.fileId}/download`,
                method: 'GET'
            })
        }),
        resetAction: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `tenders/${data.tenderId}/tasks/${data.taskId}/reset-action`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        addSupplier: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `tenders/${data.tenderId}/suppliers`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        updateSupplier: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `tenders/${data.tenderId}/suppliers/${data.supplierId}`,
                    method: 'PUT',
                    body: data.data
                };
            }
        }),
        rejectSupplier: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `tenders/${data.tenderId}/suppliers/${data.supplierId}/reject`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        shortlistSupplier: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `tenders/${data.tenderId}/suppliers/${data.supplierId}/shortlist`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        deleteSupplier: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `tenders/${data.tenderId}/suppliers/${data.supplierId}`,
                    method: 'DELETE',
                    body: data.data
                };
            }
        }),
        deleteSupplierInvite: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `tenders/${data.tender_id}/supplier-companies/delete-invite/${data.invite_id}`,
                    method: 'DELETE',
                    body: data.data
                };
            }
        }),
        UpdateTaskDate: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.tender_id}/tasks/${data.task_id}?_method=PUT`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        createTask: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.tenderId}/tasks`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        cancelTender: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.tenderId}/cancel`,
                    method: 'PUT',
                    body: { comment: data.comment }
                };
            }
        }),

        getArchive: builder.query<any, any>({
            query: (params) => ({
                url: `tenders/archive`,
                method: 'GET',
                params
            })
        }),
        getArchiveEA: builder.query<any, void>({
            query: () => ({
                url: `tenders/archive-expense-areas`,
                method: 'GET'
            })
        }),
        getNotInvitedSuppliers: builder.query<any, any>({
            query: (data) => ({
                url: `/tenders/${data.tender_id}/supplier-companies/not-invited`,
                method: 'GET',
                params: data.filter
            })
        }),
        getConfirmedSuppliers: builder.query<any, any>({
            query: (data) => ({
                url: `/tenders/${data.tender_id}/supplier-companies/confirmed`,
                method: 'GET',
                params: data.filter
            })
        }),
        sendInvitesToSuppliers: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.tender_id}/supplier-companies/send-invites`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        addSuppliersToTheTender: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.tender_id}/suppliers`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        resendForm: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data.tender_id}/tasks/resend`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        updateIncumbent: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data?.tender_id}/supplier-companies/update-incumbent/${data?.invite_id}`,
                    method: 'PUT',
                    body: data.data
                };
            }
        }),
        updateIncumbentInTender: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data?.tender_id}/suppliers/${data.supplier_id}/update-incumbent`,
                    method: 'PUT',
                    body: data.data
                };
            }
        }),
        updateManager: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data?.tender_id}/supplier-companies/update-manager/${data?.invite_id}`,
                    method: 'PUT',
                    body: data.data
                };
            }
        }),
        updateGrade: builder.mutation({
            query: (data) => {
                return {
                    url: `/tenders/${data?.tender_id}/suppliers/${data?.supplier_id}/update-grade`,
                    method: 'PUT',
                    body: data.data
                };
            }
        })
    })
});

export const {
    useLazyGetAreasQuery,
    useCreateAreaMutation,
    useUpdateAreaMutation,
    useDeleteAreaMutation,
    useCreateTenderMutation,
    useLazyGetTenderQuery,
    useUpdateTenderMutation,
    useUpdateDeadlineMutation,
    useCompleteTaskMutation,
    useDeclineTaskMutation,
    useMoveToNextPhaseMutation,
    useUpdateTaskMutation,
    useSendTaskMutation,
    useApproveTaskMutation,
    useSendActivitiesMutation,
    useDeleteFileMutation,
    useCreateSupportingDocumentMutation,
    useDeleteSupportDocMutation,
    useUpdateTaskDeadlineMutation,
    useLazyDownloadFileQuery,
    useResetActionMutation,
    useAddSupplierMutation,
    useUpdateSupplierMutation,
    useRejectSupplierMutation,
    useShortlistSupplierMutation,
    useDeleteSupplierMutation,
    useUpdateTaskDateMutation,
    useCreateTaskMutation,
    useCancelTenderMutation,
    useLazyGetArchiveQuery,
    useLazyGetArchiveEAQuery,
    useLazyGetNotInvitedSuppliersQuery,
    useSendInvitesToSuppliersMutation,
    useDeleteSupplierInviteMutation,
    useUpdateIncumbentMutation,
    useUpdateManagerMutation,
    useLazyGetConfirmedSuppliersQuery,
    useAddSuppliersToTheTenderMutation,
    useUpdateIncumbentInTenderMutation,
    useUpdateGradeMutation,
    useResendFormMutation
} = tendersApi;
