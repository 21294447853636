import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps, IconComponentProps } from '@ant-design/icons/lib/components/Icon';

type IconProps = Pick<IconComponentProps, 'onClick'>;

const PlusSvg = ({ fill }: { fill: string }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Interface, Essential/Plus, Add">
            <g id="Group">
                <g id="Group_2">
                    <path
                        id="Path"
                        d="M9.99992 6.6665V13.3332"
                        stroke={fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Path_2"
                        d="M13.3334 10.0002H6.66675"
                        stroke={fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Path_3"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5Z"
                        stroke={fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const Plus = (props: Partial<CustomIconComponentProps & IconProps>) => (
    <Icon component={() => <PlusSvg fill={props.fill || 'currentColor'} />} {...props} />
);

export default Plus;
