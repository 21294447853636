import { FC, SetStateAction, useState } from 'react';
import { PartI, taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { downloadFileFunc } from '../../../api/axiosCruds';
import edit from '../../../assets/editAdminIcon.svg';
import { useUpdateTaskMutation } from '../../../api/tenders';
import calendar from '../../../assets/Calendar, Schedule, Clock.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import Plus from '../../../assets/Plus, Add.svg';
import attantion from '../../../assets/point.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import SupplierIcon from '../../../assets/SuppDocIcon.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import { SupplierInfoModal } from '../../Modals/SupplierInfoModal';
import { ClockIcon } from '../../Icons/ClockIcon';
import { NoShowModal } from '../../Modals/NoShowModal';
import { CampusTourModal } from '../../Modals/CampusTourModal';
import Unverified from '../../../assets/Unverified.svg';
import Verified from '../../../assets/Verified.svg';
import calendarIcon from '../../../assets/calendar-chedule.svg';
import clock from '../../../assets/clock.svg';
import gradeHistory from '../../../assets/gradeHistory.svg';
import moment from 'moment';
import { Tooltip } from 'antd';
import { EditSupplierModal } from '../../Modals/EditSupplierModal';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    messageApi: any;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setDeadlineModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CampusTour: FC<DefineSupportingDocumentTaskProps> = ({
    data,
    role,
    messageApi,
    setTrigger,
    setDeadlineModal
}) => {
    const [isSupplierInfoModal, setIsSupplierInfoModal] = useState<boolean>(false);
    const [taskId, setTaskId] = useState<number | null>(null);
    const [isEditSupplier, setIsEditSupplier] = useState(false);
    const [noShowModal, setNoShowModal] = useState(false);
    const [updateTask, { isLoading: isLoadingTookPlace }] = useUpdateTaskMutation();
    const [openCampusModal, setOpenCampusModal] = useState(false);
    const [SupplierData, setSupplierData] = useState<any>(null);
    const [isEditModal, setIsEditModal] = useState(false);

    const updateTaskFunc = async (id: number) => {
        const formData = {
            ids: {
                tender_id: data?.id,
                task_id: id
            },
            data: { action: 'supplier_took_place' }
        };
        try {
            await updateTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const isToolTip = (className: string): boolean => {
        const element = document.querySelector(className);

        if (element) {
            const isTextOverflowing = element.scrollWidth > element.clientWidth;

            return isTextOverflowing;
        }

        return false;
    };

    const downloadFunc = (fileId: string, taskId: number) => {
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                const stplitArray = response.headers['content-disposition'].split(';');
                const element = stplitArray.find((elem: string) => elem.includes('filename'));
                const name = element.split('=')[1];
                downloadPDF(response, name);
            })
            .catch(() => {
                messageApi.error('Downloading Error. Check your internet connection');
            });
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });

        if (filename.includes('pdf')) {
            let headerLine = response.headers['content-disposition'];

            if (headerLine) {
                let startFileNameIndex = headerLine.indexOf('"') + 1;
                let endFileNameIndex = headerLine.lastIndexOf('"');
                filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
            }
        }

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    const statusImage = (status: string) => {
        if (status === 'Unverified') {
            return Unverified;
        }
        if (status === 'Verified') {
            return Verified;
        }
    };

    const classNameOfGrade = (key: string) => {
        if (key === 'A') {
            return 'light-Green';
        }
        if (key === 'B') {
            return 'Dark-Green';
        }
        if (key === 'C') {
            return 'Yellow';
        }
        if (key === 'D') {
            return 'Orange';
        }
        if (key === 'F') {
            return 'Brown';
        }
        if (key === 'E') {
            return 'Red';
        }
    };
    return (
        <>
            {data?.phase_tasks.length &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'campus_tour_attendee_details') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete 
                                                        `}
                                    >
                                        <div className="task-complete__title supplierCard">
                                            <div className="task-complete__title-row ">
                                                <img src={SupplierIcon} />
                                                <div className="supplier__name-info">
                                                    <div className="supplier__name">
                                                        {elem.name}
                                                        {elem.supplier_incumbent_status === 'yes' && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__email">{elem.supplier_email}</div>
                                                </div>
                                            </div>
                                            {role === 'tender_representative' && (
                                                <img
                                                    src={Plus}
                                                    className="task__plus"
                                                    style={{ margin: '0' }}
                                                    onClick={() => {
                                                        // setTaskId(elem.id);
                                                        // setIsEditSupplier(false);
                                                        // setIsSupplierInfoModal(true);
                                                        setOpenCampusModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_approve_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                            
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__row-btns">
                                                <img
                                                    src={edit}
                                                    className="task__plus"
                                                    onClick={() => {
                                                        setOpenCampusModal(true);
                                                    }}
                                                />
                                            </div>
                                        </div>{' '}
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value campus-date">
                                                <img src={calendarIcon} />
                                                {elem.campus_tour?.date}
                                            </div>
                                            <div className="task-complete__value campus-date">
                                                <img src={clock} />
                                                {elem.campus_tour?.time_from} - {elem.campus_tour?.time_to}
                                            </div>
                                        </div>
                                        {data.suppliers.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company_status_name)} />{' '}
                                                            {elem.company_status_name}
                                                        </div>
                                                        {elem.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">
                                                                ({elem.name_prefix}) {elem.name}
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {elem.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager_name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager_name ? elem.manager_name : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem?.email ? elem?.email : ''}{' '}
                                                                    {elem?.phone_number ? elem?.phone_number : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                        <Tooltip
                                                            color="#fff"
                                                            key={'#fff'}
                                                            overlayClassName="history"
                                                            title={
                                                                <div className="grade-history">
                                                                    Grade History
                                                                    {elem.grade_history?.map((grade) => {
                                                                        return (
                                                                            <div className="grade-history__item">
                                                                                <div className="grade-history__title">
                                                                                    {grade.phase_name}
                                                                                </div>
                                                                                <div className="grade-history__value">
                                                                                    {grade.grade.value_format === 'Pass'
                                                                                        ? 'Pass'
                                                                                        : role !== 'school_user' &&
                                                                                          grade.grade.value_format}
                                                                                    {grade?.grade?.key !== 'pass' && (
                                                                                        <div
                                                                                            className={`grade-history__key ${classNameOfGrade(
                                                                                                grade?.grade?.key
                                                                                            )}`}
                                                                                        >
                                                                                            {grade?.grade?.key}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            }
                                                        >
                                                            <img
                                                                src={gradeHistory}
                                                                style={{ margin: '0 10px 0 0 ', cursor: 'pointer' }}
                                                            />
                                                        </Tooltip>
                                                        <img
                                                            src={edit}
                                                            className="supplier__delete"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setSupplierData({
                                                                    manager: {
                                                                        name: elem.manager_name,
                                                                        email: elem.email,
                                                                        phone_number: elem.phone_number
                                                                    },
                                                                    company: {
                                                                        name: elem.name
                                                                    },
                                                                    id: elem.id
                                                                });
                                                                setIsEditModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="supplier__representatives">
                                                        <div>Representatives</div>
                                                        <div className="supplier__representatives__list">
                                                            {elem.payload?.campus_representatives.map((item) => {
                                                                return (
                                                                    <div className="supplier__representatives__item">
                                                                        <div className="supplier__representatives__name">
                                                                            {item.name}
                                                                        </div>
                                                                        <div className="supplier__representatives__job">
                                                                            {item.job_title}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        } else {
                        }
                    }
                })}

            {openCampusModal && (
                <CampusTourModal
                    data={data}
                    openModal={openCampusModal}
                    setOpenModal={setOpenCampusModal}
                    setTrigger={setTrigger}
                />
            )}
            {isEditModal && (
                <EditSupplierModal
                    openModal={isEditModal}
                    setOpenModal={setIsEditModal}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    SupplierData={SupplierData}
                    data={data}
                />
            )}
        </>
    );
};
